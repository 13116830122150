import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';

import moment from 'moment/moment';
import * as passwordStrengthAnalyzer from 'zxcvbn';

import { Address } from '../../models/address';
import { Invoice } from '../../models/invoice';
import { ZxcvbnResult } from '../../models/zxcvbn-result';
import { EmailStatuses, EmailStatusesEnum, EmailValidityInfo } from '../../models/email-validity-info';
import PlaceResult = google.maps.places.PlaceResult;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { CompanyType, CompanyTypeEnum } from '../../auth/registration/store/registration.reducer';


const getAddressName = (addressComponents: GeocoderAddressComponent[], name: string, short = false): string | null => {
  const addressComponent = addressComponents.find(component => component.types[0] === name);
  if (!addressComponent) {
    return null;
  }
  return short ? addressComponent.short_name : addressComponent.long_name;
};

export const formatAddressObject = (result: PlaceResult): Partial<Address> => {
  const addressComponents = result.address_components;
  const streetNumber = getAddressName(addressComponents, 'street_number');
  const route = getAddressName(addressComponents, 'route');
  return {
    name: result.formatted_address,
    street_address: streetNumber && route ? `${streetNumber} ${route}` : null,
    city: getAddressName(addressComponents, 'locality'),
    neighborhood: getAddressName(addressComponents, 'neighborhood'),
    county: getAddressName(addressComponents, 'administrative_area_level_2'),
    state: getAddressName(addressComponents, 'administrative_area_level_1', true),
    country: getAddressName(addressComponents, 'country', true),
    zip_code: getAddressName(addressComponents, 'postal_code'),
    zip_code_suffix: getAddressName(addressComponents, 'postal_code_suffix'),
  };
};

interface ClearObjectFunctionSettings {
  noEmptyStrings: boolean;
  allowNullishValues: boolean;
}

export const clearObject = <T extends object>(obj: T, settings?: Partial<ClearObjectFunctionSettings>): Partial<T> => {
  const updatedObject = { ...obj };
  Object.keys(updatedObject).forEach(key => {
    if (!settings?.allowNullishValues && (updatedObject[key] === null || updatedObject[key] === undefined)) {
      delete updatedObject[key];
    }
    if (settings?.noEmptyStrings && updatedObject[key] === '') {
      delete updatedObject[key];
    }
  });
  return updatedObject;
};

export function getUserHomePage(companyType: CompanyType): string {
  if (companyType === CompanyTypeEnum.BROKER) {
    return 'subcontractor-insurance';
  }

  if (companyType === CompanyTypeEnum.PROJECT_OWNER) {
    return 'contracts';
  }

  if (companyType === CompanyTypeEnum.LENDER) {
    return  'lender-portal';
  }

  return 'dashboard';
}

export function checkEmail(email: string, control: FormControl): boolean {
  if (email.match(/\S+@\S+\.\S+/)) {
    return true;
  }

  control.setErrors({ incorrectEmail: true });
  return false;
}

export function formatError(error: any): string {
  if (Array.isArray(error)) {
    return error.map(value => formatError(value)).join('\n').slice(0, 500);
  }

  if (typeof error === 'object') {
    return Object.entries(error).map(([key, value]) => `${key}: ${formatError(value)}`).join('\n').slice(0, 500);
  }

  return String(error).slice(0, 500);
}

export class LocationErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.touched
      ? control.invalid
      : form.form.controls.address.touched && (!!form.form.controls.address.errors || control.invalid);
  }
}

export class ConfirmPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isControlInvalid = !!(control?.invalid && control?.parent?.dirty);
    const isParentInvalid = !!(control?.parent?.invalid && control?.parent?.hasError('notSame'));

    return control.touched && (isControlInvalid || isParentInvalid);
  }
}

export function clearInvoiceObject(invoiceData: Partial<Invoice>): Partial<Invoice> {
  const invoiceFormFields = [
    'number',
    'project',
    'amount',
    'billing_from',
    'billing_to',
    'notes',
    'gc_email_cc',
    'summary_file',
    'extra_files',
    'status',
    'general_contractor',
    'is_interested_in_epp',
  ];
  const dataCopy = { ...invoiceData };
  Object.keys(dataCopy).forEach((key) => {
    if (!invoiceFormFields.includes(key)) {
      delete dataCopy[key];
    }
  });
  return dataCopy;
}

export function getFormDirtyValues<T>(formGroup: FormGroup): Partial<T> {
  return Object.keys(formGroup.controls).reduce((dirtyValues, controlName) => {
    const currentControl = formGroup.get(controlName);
    if (currentControl.dirty) {
      dirtyValues[controlName] = currentControl.value;
    }
    return dirtyValues;
  }, {});
}

export function getCookieByKey(cookieKey: string): string | undefined {
  const cookie = document.cookie.split(';').find(cookiePiece => cookiePiece.trim().startsWith(cookieKey));
  return cookie?.split('=')[1].split('"').join('');
}

export function analyzePasswordStrength(value: string): ZxcvbnResult {
  return passwordStrengthAnalyzer(value);
}

export function broadcastLocalStorageMessage(key: string, value: string = 'true'): void {
  localStorage.setItem(key, value);
  localStorage.removeItem(key);
}

export function getEmailValidityTooltipText(emailValidityInfo: EmailValidityInfo): string {
  const formattedChangedAtDate = moment(emailValidityInfo.changed_at).format('MM/DD/YYYY');

  const emailStatusToTextMapping: { [k in EmailStatuses]: string } = {
    [EmailStatusesEnum.UNKNOWN]: 'Constrafor does not have this email',
    [EmailStatusesEnum.NEW]: `Email was created on Constrafor on ${formattedChangedAtDate} but has never been contacted`,
    [EmailStatusesEnum.VALID]: `This is a valid email and the last communication was on ${formattedChangedAtDate}`,
    [EmailStatusesEnum.ERROR]: `Last email send to this email has bounced on ${formattedChangedAtDate}`,
    [EmailStatusesEnum.UNSUBSCRIBED]: `This user has unsubscribed from the Constrafor platform on ${formattedChangedAtDate}`,
    [EmailStatusesEnum.COMPLAINED]: 'Users has reported this email as spam',
  };

  return emailStatusToTextMapping[emailValidityInfo.status];
}
