import { UserService } from './core/services/user.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from './core/components/navigation/navigation.module';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from 'src/lib/google-maps-autocomplete/module/mat-google-maps-autocomplete.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatModule } from './chat/chat.module';
import { PubNubAngular } from 'pubnub-angular2';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { chatReducer } from './chat/store/reducers/chat.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { userReducer } from './core/store/reducers/user.reducer';
import { supplierCategoryReducer } from './core/store/reducers/supplier-category.reducer';
import { projectDetailsStateReducer } from './projects/store/projects.reducer';
import { NotSupportedComponent } from './core/components/not-supported/not-supported.component';
import { initializer } from './auth/auth-init';
import { NetworkInterceptor } from './core/interceptors/network.interceptor';
import { LogRocketService } from './core/services/log-rocket.service';
import { getMetaReducers } from './core/utils/logrocket-ngrx';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AdminAlertsModule } from './core/components/admin-alerts/admin-alerts.module';
import { adminAlertsReducer } from './core/components/admin-alerts/store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AdminAlertsEffects } from './core/components/admin-alerts/store/effects';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';
import { PubNubService } from './core/services/pubnub.service';
import { NotificationService } from './core/services/notification.service';
import { ChatService } from './chat/chat.service';
import { SharedModule } from './shared.module';
import { MockResponseInterceptor } from './core/interceptors/mock-response/mock-response.interceptor';
import { WebviewerComponent } from './webviewer/webviewer.component';


const interceptorsList = environment.production
  ? [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: NetworkInterceptor,
        multi: true,
      },
    ]
  : [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: NetworkInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MockResponseInterceptor,
        multi: true,
      },
    ];

@NgModule({
  declarations: [
    AppComponent,
    NotSupportedComponent,
    NotFoundComponent,
    WebviewerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NavigationModule,
    MatDatepickerModule,
    MatMomentDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCYNC2mVvulJindhiA9tgC7hSsY3ZPsJ_0',
      libraries: ['places'],
      language: 'EN',
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),
    MatTooltipModule,
    ChatModule,
    AdminAlertsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          breaks: true,
        },
      },
    }),
    StoreModule.forRoot({
      user: userReducer,
      chat: chatReducer,
      supplierCategories: supplierCategoryReducer,
      projectDetails: projectDetailsStateReducer,
      adminAlerts: adminAlertsReducer,
    }),
    EffectsModule.forRoot([
      AdminAlertsEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        LogRocketService,
        UserService,
        PubNubService,
        NotificationService,
        ChatService,
      ],
    },
    ...interceptorsList,
    PubNubAngular,
    {
      provide: ErrorHandler,
      useClass: LogRocketService,
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
