import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { resetHubspotCookiesAndReloadPage } from '../../../lib/hubspot';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response: HttpEvent<any>) => {
        if (response instanceof HttpResponse) {
          const locationFromHeader = response.headers.get('Location');
          if (locationFromHeader) {
            const urlWithoutDomain = locationFromHeader.replace(/.*\/\/[^\/]*/, '');
            this.router.navigateByUrl(urlWithoutDomain);
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 403 &&
          error.error &&
          error.error.detail &&
          error.error.detail.includes('Authentication credentials were not provided.')
        ) {
          this.authService.logout().subscribe(() => {
            this.router.navigate(['auth', 'login'], {
              queryParams: {
                redirect: this.router.url,
              },
            });
          });
          resetHubspotCookiesAndReloadPage();
        }
        return throwError(error);
      }),
    );
  }
}
