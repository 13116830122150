import { UserService } from 'src/app/core/services/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../models/user';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
  ) {
    if (environment.production || environment.staging) {
      userService.getCurrentUser$().subscribe(user => {
        this.authService.identifyUser(user);
      });
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let currentUser: User;

    this.userService.getCurrentUser$().subscribe(user => {
      currentUser = user;
    });

    if (!currentUser) {
      this.router.navigate(['auth', 'login']);
      return false;
    }

    if (currentUser.company_type === 'lender') {
      return this.handleLenderGuard(currentUser);
    }

    return true;
  }

  handleLenderGuard(user: User): boolean {
    const isStripeCompleted = user.has_stripe_account && user.has_stripe_details_submitted;
    if (user.can_send_payments && isStripeCompleted) {
      return true;
    }
    this.router.navigate(['auth', 'lender-registration']);
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
