import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';

import { mockResponses } from './mock-data';
import { ResponseStateEnum } from './mock.models';


@Injectable()
export class MockResponseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = new URL(request.url);
    const mockResponse = mockResponses.find(({ path, method }) => path === url.pathname && method === request.method);

    if (mockResponse?.state === ResponseStateEnum.SUCCESS) {
      return of(new HttpResponse(mockResponse.response));
    }

    if (mockResponse?.state === ResponseStateEnum.ERROR) {
      return throwError(mockResponse.error);
    }

    return next.handle(request);
  }
}
