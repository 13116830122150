import { Injectable } from '@angular/core';

import { User } from '../../models/user';

declare const heap: any;

@Injectable({
  providedIn: 'root',
})

export class HeapService {
  identify(user: User, adminEmail?: string, adminId?: string): void {
    try {
      heap.identify(user.id);

      heap.addUserProperties({ Name: user.first_name, Surname: user.last_name, email: user.email });

      if (adminEmail && adminId) {
        heap.track('LoginAs', { email: user.email, admin_email: adminEmail, admin_user_id: adminId });
      }
    }
    catch (error) {
      console.error(error);
    }
  }
}
