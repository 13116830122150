import { Component, Inject, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { ChatService } from '../../../../../../chat/chat.service';
import { AuthService } from '../../../../../../auth/auth.service';
import { User } from '../../../../../../models/user';
import { ID } from '../../../../../../models/id';
import { CompanyShort } from '../../../../../../models/company';
import { FormControl } from '@angular/forms';
import { resetHubspotCookiesAndReloadPage } from '../../../../../../../lib/hubspot';
import { WINDOW } from '../../../../../window/window.token';

@Component({
  selector: 'app-header-account-panel',
  templateUrl: './header-account-panel.component.html',
  styleUrls: ['./header-account-panel.component.scss'],
})
export class HeaderAccountPanelComponent implements OnInit {
  @Input() user: User;
  companyLoading: boolean;
  companies: CompanyShort[];
  unreadChatMessagesCount$: Observable<string>;
  companiesControl: FormControl = new FormControl('');

  constructor(
    private chatService: ChatService,
    private authService: AuthService,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit(): void {
    this.unreadChatMessagesCount$ = this.getUnreadMessagesObservable();
    this.authService.getUserCompanies().subscribe(companies => {
      this.companies = companies;
      this.companiesControl.setValue(this.user.company_id);
    });
  }

  private getUnreadMessagesObservable(): Observable<string> {
    return this.unreadChatMessagesCount$ = this.chatService.getTotalUnreadMessages$().pipe(
      map(count => {
        if (count <= 0) {
          return null;
        }
        return count > 99 ? '99+' : String(count);
      }),
    );
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      resetHubspotCookiesAndReloadPage();
    });
  }

  selectCompany(id: ID): void {
    this.companyLoading = true;
    this.authService.selectCompany(id).pipe(
      finalize(() => {
        this.companyLoading = false;
      }),
    ).subscribe(() => {
      this.window.location.href = '/';
    });
  }
}
