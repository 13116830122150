import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, pluck, startWith, switchMap, tap } from 'rxjs/operators';

import { SubcontractorInvite } from '../../models/invite-subcontractor.interface';
import { UserService } from './user.service';
import { Owner } from '../../models/owner';
import { OwnerInvite } from '../../models/invite-owner.interface';
import { AddProjectSubcontractor, Project } from '../../models/project';
import { projectDetailsUpdated } from '../../projects/store/projects.actions';
import { Company, CompanyContact } from '../../models/company';
import { NetworkService, Params, TypedList } from '../api/network.service';
import { ID } from '../../models/id';
import { DropdownOption } from '../../models/dropdown-option';
import { CounterpartOption } from '../../models/counterparts';


@Injectable({
  providedIn: 'root',
})
export class ContractorService {
  private newSubcontractor$ = new BehaviorSubject(false);

  constructor(
    private networkService: NetworkService,
    private userService: UserService,
    private store: Store,
  ) {
  }

  getUserContractor(): Observable<Company> {
    return this.userService.getCurrentUser$().pipe(
      switchMap(user => this.networkService.get<Company>(['constrafor', 'contractors', user.company_id])),
    );
  }

  getContractorById(contractorId: ID): Observable<Company> {
    return this.networkService.get<Company>(['constrafor', 'contractors', contractorId]);
  }

  getContractors(params?: Params): Observable<Company[]> {
    return this.networkService.get<TypedList<Company>>(['constrafor', 'contractors', params]).pipe(
      pluck('results'),
    );
  }

  getContractorsProfile(params?: Params): Observable<TypedList<CompanyContact>> {
    return this.networkService.get<TypedList<CompanyContact>>(['constrafor', 'contractors', 'profile', params]);
  }

  getContractorsShort(params?: Params): Observable<Company[]> {
    return this.networkService.get<Company[]>(['constrafor', 'contractors', 'short', params]).pipe(
      pluck('results'),
    );
  }

  getCompanies(params?: Params): Observable<Company[]> {
    return this.networkService.get<TypedList<Company>>(['constrafor', 'companies', params]).pipe(
      pluck('results'),
      map(contractors => contractors.filter(contractor => contractor.name !== null)),
    );
  }

  getCompaniesTypedList(params?: Params): Observable<TypedList<Company>> {
    return this.networkService.get<TypedList<Company>>(['constrafor', 'companies', params]).pipe(
      map(data => {
        return { ...data, results: data.results.filter(contractor => contractor.name !== null) };
      }),
    );
  }

  getOwners(params?: Params): Observable<Owner[]> {
    return this.networkService.get<TypedList<Owner>>(['constrafor', 'owners', params]).pipe(
      pluck('results'),
    );
  }

  sendInviteSubcontractor(invite: SubcontractorInvite): Observable<Company> {
    return this.networkService.post<Company>(['constrafor', 'subcontractors'], invite);
  }

  sendSubcontractorInvites(invites: SubcontractorInvite[]): Observable<Company[]> {
    return this.networkService.patch<Company[]>(['constrafor', 'subcontractors'], invites);
  }

  sendOwnerInvite(invite: OwnerInvite): Observable<Owner> {
    return this.networkService.post<Owner>(['constrafor', 'owners'], invite);
  }

  deleteSubcontractor(subcontractorId: ID): Observable<Company> {
    return this.networkService.delete<Company>(['constrafor', 'subcontractors', subcontractorId]);
  }

  addProjectSubcontractor(projectId: ID, subcontractor: AddProjectSubcontractor): Observable<Project> {
    return this.networkService.post<Project>(['constrafor', 'projects', projectId, 'subcontractors'], subcontractor).pipe(
      tap((project) => {
        this.store.dispatch(projectDetailsUpdated({
          payload: project,
        }));
      }),
    );
  }

  setScopeSpecificLimit(subcontractorId: ID, limit: { policy: string, limit: number }): Observable<Company> {
    return this.networkService.put<Company>(['constrafor', 'subcontractors', subcontractorId, 'scope-limits'], limit);
  }

  removeScopeSpecificLimit(subcontractorId: ID, policy: string): Observable<Company> {
    return this.networkService.delete<Company>(['constrafor', 'subcontractors', subcontractorId, 'scope-limits', policy]);
  }

  newSubcontractorAdded$(): Observable<boolean> {
    return this.newSubcontractor$.asObservable();
  }

  changeNewSubcontractorAdded(isAdded: boolean): void {
    this.newSubcontractor$.next(isAdded);
  }

  patchProjectPrimeContractor(projectId: ID, subcontractorId: ID, subcontractor: AddProjectSubcontractor): Observable<Project> {
    return this.networkService.patch<Project>(['constrafor', 'projects', projectId, 'subcontractors', subcontractorId], subcontractor).pipe(
      tap((project) => {
        this.store.dispatch(projectDetailsUpdated({
          payload: project,
        }));
      }),
    );
  }

  getContractorsSearchFunction(): (term: string) => Observable<DropdownOption[]> {
    const defaultValue = { value: '', viewValue: 'All' };
    return (term, extraParams?) => this.getContractorsShort(
      { page_size: 10, page: 1, name: term, as: 'subcontractor', ...extraParams },
    ).pipe(
      map((contractor: Company[]) => [
        defaultValue,
        ...contractor.map(({ id, name }) => ({ value: id, viewValue: name })),
      ]),
      startWith([defaultValue]),
    );
  }

  getCounterpartsWithUsersList(params?: Params): Observable<CounterpartOption[]> {
    return this.networkService.get<CounterpartOption[]>(['constrafor', 'counterpart_list', params]);
  }
}
