import { User } from '../../app/models/user';

declare global {
  interface Window {
    _hsq: any[];
    HubSpotConversations: any;
    hsConversationsOnReady: any;
  }
}

export const addHubspotLoadingScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'hs-script-loader';
  script.src = 'https://js.hs-scripts.com/6455330.js';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const initHubspot = (user: User) => {
  if (window.HubSpotConversations) {
    identifyHubspotUser(user);
  } else {
    const updateLiveChatIdentifyCallback = () => identifyHubspotUser(user);
    window.hsConversationsOnReady = [updateLiveChatIdentifyCallback];
  }
};

export const identifyHubspotUser = (user: User) => {
  if (user.id) {
    try {
      window._hsq = window._hsq || [];
      window._hsq.push(['identify', {
        email: user.email,
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone,
      }]);
      window._hsq.push(['trackPageView']);
      setTimeout(() => {
        window.HubSpotConversations?.widget?.refresh();
      }, 1000);
    } catch (e) {
      console.log('Unable to identify user for HubSpot', e);
    }
  }
};

export const resetHubspotCookiesAndReloadPage = () => {
  if (window._hsq) {
    setTimeout(() => window._hsq.push(['revokeCookieConsent']));
  }
};
