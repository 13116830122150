import { CompanyBriefInfo } from './company-brief-info';
import { ID } from './id';
import { Address } from './address';
import { CompanyShort } from './company';
import { CompanyType } from '../auth/registration/store/registration.reducer';

export enum Role {
  ADMIN = 1,
  RISK = 2,
  OWNER = 3,
  BROKER_MANAGER = 4,
  FIELD_OPERATIONS = 5, // read-only role, cannot change anything
  LEGAL = 6, // can access contracts only
  SALES = 7,
  ACCOUNT_PAYABLE = 8, // read-only role too
  PROJECT_MANAGER = 10,
  PROJECT_ADMIN = 11,
}

export type RoleType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 11;

export const ROLE_NAMES_MAP = {
  1: 'Admin',
  2: 'Risk',
  3: 'Project Owner',
  4: 'Broker Manager',
  5: 'Field Operations',
  6: 'Legal',
  7: 'Sales',
  8: 'Accounts Payable',
  10: 'Project Manager',
  11: 'Project Administrator',
};

export type TwoFactorAuthenticationMethods = 'email' | 'none';

export interface User {
  id: ID;
  display_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_type: CompanyType;
  company_id: ID;
  position: string;
  role: RoleType;
  profile_picture_url?: string;
  profile_picture?: number;
  registered_company?: boolean;
  manages_companies?: CompanyBriefInfo[];
  notification_channel?: string;
  permissions: string[];
  chatrooms: string[];
  visited_pages: string[];
  is_company_gc: boolean;
  is_verified: boolean;
  has_stripe_account: boolean;
  has_stripe_details_submitted: boolean;
  can_send_payments: boolean;
  mfa_method: TwoFactorAuthenticationMethods;
  new_backup_code: string;
  is_profile_update_needed_for_epp: boolean;
  is_project_documents_needed_for_epp: boolean;
  company_overview_completed_for_epp: boolean;
  company_has_integration: boolean;
  company_can_send_payments: boolean;
}

export interface PendingMembershipUserInfo {
  id: ID;
  first_name: string;
  last_name: string;
  email: string;
}

export enum MembershipStatusEnum {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export enum MembershipRequestTypeEnum {
  NATURAL = 'natural',
  INVITATION = 'invitation',
}

export type MembershipRequestType = MembershipRequestTypeEnum.NATURAL | MembershipRequestTypeEnum.INVITATION;

export interface PendingMembership {
  id: ID;
  company: CompanyShort;
  user: PendingMembershipUserInfo;
  address: Address;
  company_name: string;
  company_phone: string;
  company_type: CompanyType;
  request_type: MembershipRequestType;
  status: MembershipStatusEnum.PENDING;
  code: string;
  requested_role: RoleType;
}
