import { PubNubChannel, PubNubMessage, PubNubMetaMessage } from '../../../models/chat.interface';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addChannel,
  addMessageToChannel,
  setActiveCompanyChat,
  setTotalUnreadMessages,
  updateChannel,
  updateChannels,
  updateMessageInChannel,
  updateMessages,
  clearMessages,
  clearChannels,
} from '../actions/chat.actions';

export interface ChatInitialState {
  channels: PubNubChannel[];
  messages: { [channel: string]: Array<PubNubMessage | PubNubMetaMessage> };
  activeCompanyChat: number;
  totalUnreadMessages: number;
}


export const initialState: ChatInitialState = {
  channels: [],
  messages: {},
  activeCompanyChat: null,
  totalUnreadMessages: 0,
};

const reducer = createReducer(
  initialState,
  on(updateChannels, (state: ChatInitialState, { payload }) => {
    return {
      ...state,
      channels: payload.channels,
    };
  }),
  on(updateMessages, (state: ChatInitialState, { payload }) => {
    if (!payload.channel) {
      return { ...state };
    }
    const messages = Object.assign({}, state.messages, { [payload.channel]: payload.messages });
    return {
      ...state,
      messages,
    };
  }),
  on(addMessageToChannel, (state: ChatInitialState, { payload }) => {
    const oldMessagesForChannel = state.messages[payload.channelName] || [];
    const messages = {
      ...state.messages,
      [payload.channelName]: [...oldMessagesForChannel, payload.message],
    };
    return {
      ...state,
      messages,
    };
  }),
  on(updateMessageInChannel, (state: ChatInitialState, { payload }) => {
    const oldMessagesForChannel: PubNubMessage[] = state.messages[payload.channelName] || [];
    const filteredMessages = oldMessagesForChannel.filter(message => message.id !== payload.message.id);
    const messages = {
      ...state.messages,
      [payload.channelName]: [...filteredMessages, payload.message],
    };
    return {
      ...state,
      messages,
    };
  }),
  on(addChannel, (state: ChatInitialState, { payload }) => {
    return {
      ...state,
      channels: [payload.channel, ...state.channels],
    };
  }),
  on(updateChannel, (state: ChatInitialState, { payload }) => {
    return {
      ...state,
      channels: state.channels.map(c => (c.title === payload.channel.title ? payload.channel : c)),
    };
  }),
  on(setActiveCompanyChat, (state: ChatInitialState, { payload }) => {
    return {
      ...state,
      activeCompanyChat: payload.companyId,
    };
  }),
  on(setTotalUnreadMessages, (state: ChatInitialState, { payload }) => {
    return {
      ...state,
      totalUnreadMessages: payload.messageCount,
    };
  }),
  on(clearMessages, (state: ChatInitialState) => {
    return {
      ...state,
      messages: {},
    };
  }),
  on(clearChannels, (state: ChatInitialState) => {
    return {
      ...state,
      channels: [],
    };
  }),
);

export function chatReducer(state: ChatInitialState, action: Action): ChatInitialState {
  return reducer(state, action);
}
