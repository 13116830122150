import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdminAlert } from '../../interfaces/admin-alert';
import { AdminAlertsService } from '../../services/admin-alerts.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { PAGES_WITHOUT_HEADER } from '../../../navigation/header/header.component';


const PAGES_WITH_ADMIN_ALERTS = [
  '/contracts/contracts-page/my-contracts',
  '/contracts/contracts-page/my-templates',
  '/projects',
  '/certificates-of-insurance/dashboard',
  '/certificates-of-insurance/certificates-page/projects',
  '/certificates-of-insurance/certificates-page/certificates',
  '/certificates-of-insurance/subcontractors',
  '/certificates-of-insurance/reports',
  '/subcontractor-insurance',
  '/subcontractor-insurance/discover',
  '/lending',
  '/invoicing/payments/send',
  '/invoicing/payments/pending',
  '/invoicing/payments/history',
  '/messages',
  '/user/profile',
  '/user/users-and-roles',
  '/user/notification-settings',
  '/user/billing',
  '/invoicing/accounts-payable',
  '/invoicing/dashboard',
  '/invoicing/invoices',
  '/invoicing/payment-history',
  '/contacts/contacts-page/contacts',
  '/dashboard',
];

@Component({
  selector: 'app-admin-alerts-container',
  templateUrl: './admin-alerts-container.component.html',
  styleUrls: ['./admin-alerts-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAlertsContainerComponent implements OnInit, OnDestroy {
  readonly alerts$: Observable<AdminAlert[]>;
  visible$: Observable<boolean>;

  private readonly destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private adminAlertsService: AdminAlertsService,
    private router: Router,
  ) {
    this.alerts$ = this.adminAlertsService.getItems();
  }

  ngOnInit(): void {
    this.visible$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !PAGES_WITHOUT_HEADER.some(page => this.router.url.startsWith(page))
        && PAGES_WITH_ADMIN_ALERTS.some(page => this.router.url.startsWith(page))),
      takeUntil(this.destroyed$),
    );

    this.adminAlertsService.startPulling()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onDismiss(alertId: string): void {
    this.adminAlertsService.hideAlert(alertId);
  }

}
