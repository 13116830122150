import { Injectable } from '@angular/core';
import { Actions, createEffect, CreateEffectMetadata, ofType } from '@ngrx/effects';
import { fetchAdminAlerts, hideAdminAlert, setAdminAlertsItems } from './actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserService } from '../../../services/user.service';

@Injectable()
export class AdminAlertsEffects {

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {
  }

  fetchAlerts$: CreateEffectMetadata = createEffect(() => this.actions$.pipe(
    ofType(fetchAdminAlerts),
    switchMap(() => this.userService.getAdminAlerts()
      .pipe(
        map(alerts => setAdminAlertsItems({ payload: alerts })),
        catchError(() => EMPTY),
      ),
    )),
  );

  hideAlert$: CreateEffectMetadata = createEffect(() => this.actions$.pipe(
    ofType(hideAdminAlert),
    switchMap((action) => this.userService.getAdminAlerts()
      .pipe(
        map(alerts => setAdminAlertsItems({ payload: alerts.filter(alert => alert.id !== action.payload) })),
        catchError(() => EMPTY),
      ),
    )),
  );

}
