import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ChatInitialState } from '../reducers/chat.reducer';

export const selectChat = createFeatureSelector<ChatInitialState>('chat');

export const selectChannels = createSelector(
  selectChat,
  chat => chat.channels,
);

export const selectMessages = createSelector(
  selectChat,
  chat => chat.messages,
);

export const selectActiveCompanyChat = createSelector(
  selectChat,
  chat => chat.activeCompanyChat,
);

export const selectTotalUnreadMessages = createSelector(
  selectChat,
  chat => chat.totalUnreadMessages,
);
