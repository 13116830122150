import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotSupportedComponent } from './core/components/not-supported/not-supported.component';
import { ChatComponent } from './chat/chat/chat.component';
import { BrowserGuard } from './core/guards/browser.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { MobileGuard } from './core/guards/mobile.guard';
import { NotAuthGuard } from './core/guards/not-auth.guard';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SubcontractorInsuranceDiscoverComponent } from './coi-subcontractor/subcontractor-insurance-discover/subcontractor-insurance-discover.component';
import { DashboardGuard } from './core/guards/dashboard.guard';
import { PermissionsGuard } from './core/guards/permissions.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [BrowserGuard, AuthGuard],
    children: [
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'early-pay-requests',
        loadChildren: () => import('./early-pay-requests/early-pay-requests.module').then(m => m.EarlyPayRequestsModule),
      },
      {
        path: 'certificates-of-insurance',
        loadChildren: () => import('./coi-general-contractor/certificates.module').then(m => m.CertificatesModule),
      },
      {
        path: 'subcontractor-insurance',
        loadChildren: () => import('./coi-subcontractor/subcontractor-insurance.module').then(m => m.SubcontractorInsuranceModule),
      },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
      },
      {
        path: 'contracts',
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
      },
      {
        path: 'invoicing',
        loadChildren: () => import('./invoicing/invoicing.module').then(m => m.InvoicingModule),
        canActivate: [PermissionsGuard],
        data: {
          permissions: ['constrafor.can_view_invoice_tab'],
        },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [DashboardGuard],
      },
      {
        path: 'diversity',
        loadChildren: () => import('./diversity/diversity.module').then(m => m.DiversityModule),
      },
      {
        path: 'company-profile',
        loadChildren: () => import('./company-profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'messages',
        canActivate: [MobileGuard],
        component: ChatComponent,
      },
      {
        path: 'messages/:chatTitle',
        canActivate: [MobileGuard],
        component: ChatComponent,
      },
    ],
  },
  {
    path: 'lender-portal',
    loadChildren: () => import('./lender-portal/lender-portal.module').then(m => m.LenderPortalModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contractors',
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule),
  },
  {
    path: 'insurance',
    component: SubcontractorInsuranceDiscoverComponent,
  },
  {
    path: 'building-owner',
    canActivate: [MobileGuard],
    loadChildren: () => import('./building-owner/building-owner.module').then(m => m.BuildingOwnerModule),
  },
  {
    path: 'lending',
    loadChildren: () => import('./lending/lending.module').then(m => m.LendingModule),
  },
  {
    path: 'auth',
    canActivate: [NotAuthGuard, BrowserGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'not-supported',
    component: NotSupportedComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
