import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { Permission } from '../../models/permission';
import { isArray } from 'rxjs/internal-compatibility';


// tslint:disable:no-input-rename
@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective implements OnChanges {
  @Input() appPermission: Permission | Permission[];
  @Input('appPermissionAnd') displayCondition = true;
  @Input('appPermissionElse') alternativeTemplateRef: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {
  }

  ngOnChanges(): void {
    const permissionsArray = isArray(this.appPermission) ? this.appPermission : [this.appPermission];
    const hasPermission = this.userService.hasPermissions(permissionsArray);
    if (hasPermission && this.displayCondition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.alternativeTemplateRef) {
      this.viewContainer.createEmbeddedView(this.alternativeTemplateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
