<button
  mat-icon-button
  class="notifications"
  [matMenuTriggerFor]="notificationsMenu"
  (click)="markLastSeenNotification()"
>
  <mat-icon
    class="notifications-icon"
    matBadgeSize="small"
    matBadgeColor="warn"
    [ngStyle]="{ left: (unreadNotificationsCount$ | async) ? '-5px' : '-3px' }"
    [matBadge]="unreadNotificationsCount$ | async"
  >
    notifications
  </mat-icon>
</button>

<mat-menu #notificationsMenu class="notification-panel">
  <mat-action-list *ngIf="notifications$ | async as notifications" class="notification-list">
    <ng-container *ngFor="let notification of notifications; trackBy: trackByTimetoken">
      <mat-list-item (click)="notification.action && notification.action()">
        <div mat-line class="notification-title-wrapper">
          <span class="notification-title">
            {{notification.title}}
          </span>
          <span class="notification-date">
            {{notification.timetoken / 10000 | date:'MM/dd/yyyy h:mm a'}}
          </span>
        </div>
        <div mat-line class="notification-subtitle-wrapper">
          <p class="notification-subtitle">
            {{notification.subtitle}}
          </p>
        </div>
        <mat-icon
          class="remove-notification"
          (click)="removeNotification($event, notification)"
        >
          close
        </mat-icon>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
    <div
      *ngIf="notifications.length > 0"
      class="clear-all"
      (click)="removeAllNotifications()"
    >
      Clear all
    </div>
    <div
      *ngIf="notifications.length === 0"
      class="notification-list-empty"
    >
      You have no notifications
    </div>
  </mat-action-list>
</mat-menu>
