export interface EmailValidityInfo {
  email: string;
  status: EmailStatuses;
  changed_at: string;
}

export type EmailStatuses = 'unknown' | 'new' | 'valid' | 'error' | 'unsubscribed' | 'complained';

export enum EmailStatusesEnum {
  UNKNOWN = 'unknown',
  NEW = 'new',
  VALID = 'valid',
  ERROR = 'error',
  UNSUBSCRIBED = 'unsubscribed',
  COMPLAINED = 'complained',
}
