import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, ResolveEnd, ResolveStart, Router, RouterEvent } from '@angular/router';

import { RouteHistoryService } from './core/services/route-history.service';
import { PdfjsExpressService } from './core/services/pdfjs-express.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = false;
  isPdfjsExpressViewerActive$: Observable<boolean>;

  constructor(
    private router: Router,
    private routeHistoryService: RouteHistoryService,
    public pdfjsExpressService: PdfjsExpressService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: RouterEvent) => this.handleRouterEvent(event));
    this.isPdfjsExpressViewerActive$ = this.pdfjsExpressService.isViewerActive$.asObservable();
    this.isPdfjsExpressViewerActive$.subscribe(value => {
      if (value) {
        this.startManualChangeDetection();
      }
    });
  }

  private handleRouterEvent(routerEvent: RouterEvent): void {
    if (routerEvent instanceof ResolveStart) {
      this.loading = true;
    }

    if (routerEvent instanceof ResolveEnd) {
      this.loading = false;
    }

    if (routerEvent instanceof NavigationEnd) {
      this.routeHistoryService.handleUrlChange(routerEvent.urlAfterRedirects);
    }
  }

  startManualChangeDetection(): void {
    this.pdfjsExpressService.stopManualChangeDetection();
    this.pdfjsExpressService.cdrInterval = setInterval(() => {
      this.cdr.detectChanges();
    }, 300);
  }
}
