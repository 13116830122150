<ng-container *ngIf="visible$ | async">
  <app-admin-alert
    *ngFor="let alert of alerts$ | async"
    class="alert--spacing"
    [level]="alert.level"
    [message]="alert.message"
    [isClosable]="alert.is_closable"
    (dismiss)="onDismiss(alert.id)">
  </app-admin-alert>
</ng-container>
