import { Action, createReducer, on } from '@ngrx/store';
import { SupplierCategory } from 'src/app/models/supplier-category';
import { updateSupplierCategories } from '../actions/supplier-category.actions';

export type SupplierCategoryState = SupplierCategory[];

export const initialState: SupplierCategoryState = [];

const reducer = createReducer(
  initialState,
  on(updateSupplierCategories, (state: SupplierCategoryState, { payload }) => {
    return payload;
  }),
);

export function supplierCategoryReducer(state: SupplierCategoryState | undefined, action: Action): SupplierCategory[] {
  return reducer(state, action);
}
