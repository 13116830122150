import { animate, group, query, state, style, transition, trigger } from '@angular/animations';


// Routable animations
const dialogCloseAnimation =
  trigger('openClose', [
    state('edit', style({
      opacity: 1,
    })),
    state('submit', style({
      opacity: 0.5,
    })),
    state('complete', style({
      height: '60px',
      opacity: 0,
    })),
    transition('edit => submit', [
      animate('0.5s'),
    ]),
    transition('submit => complete', [

      query('.header, .Rectangle-681, .dialog-content, #dialog-actions', [
        group([
          animate('0.4s',
            style({ opacity: 0 }),
          ),
          animate('0.8s',
            style({ height: '0px' }),
          ),
        ])]),
    ]),
    transition('submit => edit', [
      animate('1s'),
    ]),
  ]);

const dialogCompleteAnimation =
  trigger('completeBox', [
    state('complete', style({
      height: '60px',
      opacity: 1,
      visibility: 'visible',
    })),
    state('*', style({
      height: '0px',
      opacity: 0,
      visibility: 'hidden',
    })),
    transition('submit => complete', [
      animate('1.5s ease'),
    ]),
  ]);

export { dialogCompleteAnimation, dialogCloseAnimation };
