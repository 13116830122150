import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminAlertComponent } from './components/admin-alert/admin-alert.component';
import { AdminAlertsContainerComponent } from './components/admin-alerts-container/admin-alerts-container.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AdminAlertComponent,
    AdminAlertsContainerComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MarkdownModule.forChild(),
  ],
  exports: [
    AdminAlertComponent,
    AdminAlertsContainerComponent,
  ],
})
export class AdminAlertsModule { }
