import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FileSizePipe } from '../../core/pipes/file-size.pipe';
import { CDialogComponent } from '../../core/cdialog/cdialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CompanyHeaderComponent } from '../../core/components/navigation/company-header/company-header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { FormatAddressPipe } from '../../core/pipes/format-address.pipe';
import { PermissionDirective } from '../../core/directives/permission.directive';
import { HasPermissionsDirective } from '../../core/directives/has-permissions.directive';


@NgModule({
  declarations: [
    CompanyHeaderComponent,
    FileSizePipe,
    CDialogComponent,
    FormatAddressPipe,
    PermissionDirective,
    HasPermissionsDirective,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
  ],
  providers: [
    TitleCasePipe,
  ],
  exports: [
    CompanyHeaderComponent,
    FileSizePipe,
    CDialogComponent,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormatAddressPipe,
    PermissionDirective,
    HasPermissionsDirective,
  ],
})
export class SharedInitialModule { }
