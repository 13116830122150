import { Component, OnInit } from '@angular/core';
import { NotificationItem, NotificationService } from '../../../services/notification.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications$: Observable<NotificationItem[]>;
  unreadNotificationsCount$: Observable<string>;

  constructor(
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.notifications$ = this.notificationService.getNotifications();
    this.unreadNotificationsCount$ = this.notificationService.getUnseenNotificationsCount();
  }

  markLastSeenNotification(): void {
    this.notificationService.markNotificationAsSeen();
  }

  removeNotification(event: MouseEvent, notification: NotificationItem): void {
    event.stopPropagation();
    event.preventDefault();

    this.notificationService.removeNotification(notification);
  }

  removeAllNotifications(): void {
    this.notificationService.removeAllNotifications();
  }

  trackByTimetoken(index: number, notification: NotificationItem): number {
    return notification.timetoken;
  }

}
