import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../services/user.service';
import { getUserHomePage } from '../utils';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class NotAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let currentUser: User;

    this.userService.getCurrentUser$().subscribe(user => {
      currentUser = user;
    });

    if (!currentUser) {
      return true;
    }

    if (currentUser.company_type === 'lender') {
      return this.handleLenderGuard(state, currentUser);
    }
    this.router.navigate([getUserHomePage(currentUser.company_type)]);
    return false;
  }

  handleLenderGuard(state: RouterStateSnapshot, user: User): boolean {
    const isStripeCompleted = user.has_stripe_account && user.has_stripe_details_submitted;
    if (user.can_send_payments && isStripeCompleted) {
      this.router.navigate([getUserHomePage(user.company_type)]);
      return false;
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}
