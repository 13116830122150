import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentType, FilePreUpload, FileUpload } from '../../models/file-upload';
import { CoiRequestFile, FileExtractedData } from '../../models/insurance-requests-grouped';
import { UUID } from '../../models/id';
import { NetworkService, Params } from '../api/network.service';
import { ExtendedFileUpload } from '../../coi-subcontractor/coi-manager/coi-manager.service';


@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private networkService: NetworkService,
  ) {
  }

  getFile(uuid: UUID): Observable<FileUpload> {
    return this.networkService.get<FileUpload>(['file-manager', 'files', uuid]);
  }

  postFile(file: FormData): Observable<FileUpload> {
    return this.networkService.post<FileUpload>(['file-manager', 'files'], file);
  }

  putFile(uuid: UUID, file: FormData): Observable<FileUpload> {
    return this.networkService.put<FileUpload>(['file-manager', 'files', uuid], file);
  }

  deleteFile(uuid: UUID): Observable<null> {
    return this.networkService.delete<null>(['file-manager', 'files', uuid]);
  }

  deleteProjectFile(uuid: UUID): Observable<any> {
    return this.networkService.delete<FileUpload>(['file-manager', 'project_files', uuid]);
  }

  uploadFile(file: File, newFileName?: string): Observable<FileUpload> {
    const formData: FormData = new FormData();
    const fileName = newFileName || file.name;
    formData.append('file', file, fileName);
    formData.append('name', fileName);
    formData.append('size', String(file.size));
    return this.postFile(formData);
  }

  uploadMultipleFiles(files: File[]): Observable<FileUpload[]> {
    const uploadFiles$ = files.map(file => this.uploadFile(file));
    return uploadFiles$.length > 0 ? forkJoin(uploadFiles$) : of([]);
  }

  replaceFile(uuid: UUID, file: File): Observable<FileUpload> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    formData.append('size', String(file.size));
    return this.putFile(uuid, formData);
  }

  uploadProjectFile(file: File, documentType: DocumentType): Observable<FileUpload> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    formData.append('size', String(file.size));
    formData.append('doc_type', documentType);
    return this.networkService.post<FileUpload>(['file-manager', 'project_files'], formData);
  }

  extractFileInfo(uuid: UUID, params: Params): Observable<{ expiry_date: FileExtractedData }> {
    return this.networkService.get<{ expiry_date: FileExtractedData }>(
      ['file-manager', 'files', uuid, 'extract', params],
    );
  }

  prepareFileRequests(filesToUpload: FilePreUpload[], filesToRemove: FileUpload[]): Observable<UUID>[] {
    if (filesToUpload.length || filesToRemove.length) {
      const filesToUpload$ = filesToUpload.map(
        fileToUpload => this.uploadProjectFile(fileToUpload.file, fileToUpload.doc_type).pipe(
          map(file => file.uuid),
        ),
      );
      const filesToDelete$ = filesToRemove.map(
        file => this.deleteProjectFile(file.uuid),
      );

      return [
        ...filesToUpload$,
        ...filesToDelete$,
      ];
    }
    return [];
  }

  getCoiRequestFilesSortOrder(a: CoiRequestFile, b: CoiRequestFile, activeSort: string, isAsc: boolean): number {
    switch (activeSort) {
      case 'file_name':
        return this.compareStrings(a.file_name, b.file_name, isAsc);
      case 'created_on':
        return this.compareStrings(a.created_on, b.created_on, isAsc);
      case 'updated_on':
        return this.compareUpdatedOnDates(a, b, isAsc);
      default:
        return 0;
    }
  }

  getUploadedFilesSortOrder(a: ExtendedFileUpload, b: ExtendedFileUpload, activeSort: string, isAsc: boolean): number {
    switch (activeSort) {
      case 'name':
        return this.compareStrings(a.name, b.name, isAsc);
      case 'created_on':
        return this.compareStrings(a.created_on, b.created_on, isAsc);
      case 'updated_on':
        return this.compareUpdatedOnDates(a, b, isAsc);
      default:
        return 0;
    }
  }

  private compareUpdatedOnDates(a: CoiRequestFile | ExtendedFileUpload, b: CoiRequestFile | ExtendedFileUpload, isAsc: boolean): number {
    if (a.updated_by && b.updated_by) {
      return this.compareStrings(a.updated_on, b.updated_on, isAsc);
    }
    if (a.updated_by && !b.updated_by) {
      return 1 * (isAsc ? 1 : -1);
    }
    if (!a.updated_by && b.updated_by) {
      return -1 * (isAsc ? 1 : -1);
    }
    return 0;
  }

  private compareStrings(a: string, b: string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
