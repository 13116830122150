import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { MetaReducer } from '@ngrx/store';


const logrocketMiddleware = createNgrxMiddleware(LogRocket);

export function getMetaReducers(): MetaReducer[] {
  return [logrocketMiddleware];
}
