import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { User } from '../../../../../models/user';
import { getUserHomePage } from '../../../../utils';
import { AuthService } from '../../../../../auth/auth.service';
import { resetHubspotCookiesAndReloadPage } from '../../../../../../lib/hubspot';
import { CompanyType } from '../../../../../auth/registration/store/registration.reducer';


@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent implements OnChanges {
  @Input() user: User;
  @ViewChild('desktopMenu') textMenuItems: ElementRef;

  companyType: CompanyType = null;
  homePage = '/';

  private unsubscribe$ = new Subject();

  constructor(
    private authService: AuthService,
  ) {
  }

  ngOnChanges(): void {
    this.companyType = this.user?.company_type;
    this.homePage = getUserHomePage(this.companyType);
  }

  startScrollRight(): void {
    this.startMenuScrolling('right');
  }

  startScrollLeft(): void {
    this.startMenuScrolling('left');
  }

  stopScroll(): void {
    this.unsubscribe$.next();
  }

  private startMenuScrolling(direction: 'right' | 'left'): void {
    let step = 10;
    let stepInc = 1;
    if (direction === 'left') {
      step *= -1;
      stepInc *= -1;
    }
    interval(30)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          if (Math.abs(step) < 30) {
            step += stepInc;
          }
          this.textMenuItems.nativeElement.scrollLeft = this.textMenuItems.nativeElement.scrollLeft + step;
        },
      );
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      resetHubspotCookiesAndReloadPage();
    });
  }
}
