import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild, Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { UserService } from '../services/user.service';
import { ConfirmDialogComponent, ConfirmDialogSettings } from '../../ui/confirm-dialog/confirm-dialog.component';
import { RouteHistoryService } from '../services/route-history.service';
import { getUserHomePage } from '../utils';


@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private matDialog: MatDialog,
    private routeHistoryService: RouteHistoryService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = route.data.permissions || [] as string[];
    const hasAccess = this.userService.hasPermissions(permissions);

    if (hasAccess) {
      return true;
    }

    this.handleNoAccess();
    return false;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  private handleNoAccess(): void {
    this.matDialog.open<ConfirmDialogComponent, ConfirmDialogSettings>(ConfirmDialogComponent, {
      data: {
        title: `Permission denied`,
        content: 'Your company does not have access to this module. Contact your administrator to get access.',
        hideConfirmButton: true,
      },
    });
    this.checkIfShouldGoHome();
  }

  private checkIfShouldGoHome(): void {
    const isEmptyRoutingHistoryState = !this.routeHistoryService.currentUrl.getValue()
      && !this.routeHistoryService.previousUrl.getValue();

    if (isEmptyRoutingHistoryState) {
      this.userService.getCurrentUser$().subscribe(
        ({ company_type }) => this.router.navigate([getUserHomePage(company_type)]),
      );
    }
  }
}
