import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import LogRocket from 'logrocket';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService implements ErrorHandler {
  constructor(
    private http: HttpClient,
    private ngZone: NgZone,
  ) {
  }

  init(): Observable<{ release: string }> {
    return this.getLogrocketConfig().pipe(
      tap(config => {
        const logRocketConfig = {
          release: config.release,
          mergeIframes: true,
          network: {
            requestSanitizer: request => {
              if (request.url.toLowerCase().includes('users/login') && request.body) {
                request.body = null;
                return request;
              }

              return request;
            },
          },
        };

        this.ngZone.runOutsideAngular(() => {
          if (environment.production) {
            LogRocket.init('yixn7y/prod-frontend', logRocketConfig);
          }

          if (environment.staging) {
            LogRocket.init('yixn7y/staging-frontend', logRocketConfig);
          }
        });

        window.addEventListener('offline', () => {
          console.warn('Lost connection to network');
        });
      }),
    );
  }

  private getLogrocketConfig(): Observable<{ release: string }> {
    if (environment.production || environment.staging) {
      return this.http.get<{ release: string }>(environment.HOST_URL + '.config/config.json').pipe(
        catchError(() => {
          return of({ release: '' });
        }),
      );
    }

    return of({ release: '' });
  }


  handleError(error: Error | HttpErrorResponse): void {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.location.reload();
    }

    if (error instanceof HttpErrorResponse) {
      LogRocket.error(error);
    } else {
      LogRocket.captureException(error);
    }

    console.error(error);
  }
}
