import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminAlertLevel } from '../../types';

@Component({
  selector: 'app-admin-alert',
  templateUrl: './admin-alert.component.html',
  styleUrls: ['./admin-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminAlertComponent {
  @Input() message = '';
  @Input() level: AdminAlertLevel = 'default';
  @Input() isClosable = true;

  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();

  onClose(): void {
    this.dismiss.emit();
  }
}
