<div class="icon-menu-items">
  <mat-form-field *ngIf="companies?.length > 1" class="company-dropdown">
    <mat-select [formControl]="companiesControl">
      <mat-option *ngFor="let company of companies" [value]="company.id" (click)="selectCompany(company.id)">
        {{company.display_name}}
      </mat-option>
    </mat-select>
    <div *ngIf="companyLoading" class="company-loading">
      <mat-spinner [diameter]="45" [strokeWidth]="4"></mat-spinner>
    </div>
  </mat-form-field>
  <a class="messages-icon mat-icon-button desktop-only" routerLink="/messages">
  <span class="mat-button-wrapper">
    <mat-icon
      [ngStyle]="{left: (unreadChatMessagesCount$ | async) ? '-5px' : '-3px'}"
      [matBadge]="unreadChatMessagesCount$ | async"
      matBadgeSize="small"
      matBadgeColor="warn"
    >
      chat_bubble
    </mat-icon>
  </span>
  </a>
  <app-notifications></app-notifications>

  <button
    mat-icon-button
    [matMenuTriggerFor]="profileMenu"
    class="account desktop-only"
    color="primary"
    aria-label="Account"
  >
    <mat-icon *ngIf="!user || !user.profile_picture_url" class="profile-pic-icon">account_circle</mat-icon>
    <img
      class="profile-pic-img"
      alt="profile"
      *ngIf="user && user.profile_picture_url"
      [src]="user.profile_picture_url"
    />
  </button>
</div>

<mat-menu #profileMenu="matMenu">
  <a mat-menu-item routerLink="/company-profile">
    <mat-icon class="profile-pic-icon">account_circle</mat-icon>
    <span>Profile</span>
  </a>
  <a mat-menu-item routerLink="/user/profile">
    <mat-icon class="menu-icon">settings</mat-icon>
    <span>Settings</span>
  </a>
  <a mat-menu-item (click)="logout()">
    <mat-icon class="menu-icon">exit_to_app</mat-icon>
    <span>Log Out</span>
  </a>
</mat-menu>
