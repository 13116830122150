import { Action, createReducer, on } from '@ngrx/store';

import { projectDetailsResetState, projectDetailsUpdated } from './projects.actions';
import { Project } from '../../models/project';


const projectDetailsInitialState = null;

const projectReducer = createReducer(
  projectDetailsInitialState,
  on(projectDetailsUpdated, (state: Project, { payload }) => {
    return payload;
  }),
  on(projectDetailsResetState, () => {
    return projectDetailsInitialState;
  }),
);

export function projectDetailsStateReducer(state: Project | undefined, action: Action): Project {
  return projectReducer(state, action);
}
