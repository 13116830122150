import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './ui/confirm-dialog/confirm-dialog.component';
import { PieChartComponent } from './ui/pie-chart/pie-chart.component';
import { StatusPanelComponent } from './ui/status-panel/status-panel.component';
import { CustomDropdownFilterComponent } from './ui/custom-dropdown-filter/custom-dropdown-filter.component';
import { CustomSearchDropdownFilterComponent } from './ui/custom-search-dropdown-filter/custom-search-dropdown-filter.component';
import { DateRangePickerComponent } from './ui/date-range-picker/date-range-picker.component';
import { RouterModule } from '@angular/router';
import { SubcontractorTierComponent } from './ui/subcontractor-tier/subcontractor-tier.component';
import { SplitWordsPipe } from './core/pipes/split-words.pipe';
import { InformationDialogComponent } from './ui/information-dialog/information-dialog.component';
import { ProjectsFilterComponent } from './ui/projects-filter/projects-filter.component';
import { DocumentsFileUploaderComponent } from './ui/document-file-uploader/documents-file-uploader.component';
import { SendMessageDialogComponent } from './ui/send-message-dialog/send-message-dialog.component';
import { AutocompleteDropdownComponent } from './ui/autocomplete-dropdown/autocomplete-dropdown.component';
import { MaterialModule } from './material.module';
import { TableComponent } from './ui/table/table.component';
import { MultipleFileUploaderComponent } from './ui/multiple-file-uploader/multiple-file-uploader.component';
import { NewProjectSectionComponent } from './ui/new-project-section/new-project-section.component';
import { MatGoogleMapsAutocompleteModule } from 'src/lib/google-maps-autocomplete/module/mat-google-maps-autocomplete.module';
import { TexturaConnectDialogComponent } from './ui/textura-connect-dialog/textura-connect-dialog.component';
import { LocationSelectComponent } from './ui/location-select/location-select.component';
import { ProjectExperiencesComponent } from './company-profile/profile-page/project-experiences/project-experiences.component';
import { ProjectEndorsementsListComponent } from './ui/company-profile-components/project-endorsements-list/project-endorsements-list.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { NavigationModule } from './core/components/navigation/navigation.module';
import { SharedInitialModule } from './ui/shared-modules/shared-initial.module';
import { BrokerSectionComponent } from './ui/broker-section/broker-section.component';
import { IconWithTooltipComponent } from './ui/icon-with-tooltip/icon-with-tooltip.component';
import { BackToHeaderComponent } from './ui/back-to-header/back-to-header.component';
import { TableFiltersPanelComponent } from './ui/table-filters-panel/table-filters-panel.component';
import { FilePanelComponent } from './ui/file-panel/file-panel.component';
import { PlaidPanelComponent } from './ui/plaid-panel/plaid-panel.component';
import { StripePanelComponent } from './ui/stripe-panel/stripe-panel.component';
import { ColumnChartComponent } from './ui/column-chart/column-chart.component';
import { CompanyInsuranceLossHistoryComponent } from './ui/company-profile-components/company-insurance-loss-history/company-insurance-loss-history.component';
import { CompanyInsuranceBondingLimitsComponent } from './ui/company-profile-components/company-insurance-bonding-limits/company-insurance-bonding-limits.component';
import { CompanyDiversityCertificatesListComponent } from './ui/company-profile-components/company-diversity-certificates-list/company-diversity-certificates-list.component';
import { InviteCompanyBrokerDialogComponent } from './ui/invite-company-broker-dialog/invite-company-broker-dialog.component';
import { AddBrokerBlockComponent } from './ui/add-broker-block/add-broker-block.component';
import { ButtonLoadingDirective } from './core/directives/button-loading.directive';
import { PasswordStrengthAnalyzerComponent } from './auth/password-strength-analyzer/password-strength-analyzer.component';
import { TextLimitDirective } from './core/directives/text-limit.directive';
import { ProjectPaymentsProgressComponent } from './projects/project-detail/components/project-payments-progress/project-payments-progress.component';
import { PaymentsProgressPiechartComponent } from './ui/payments-progress-piechart/payments-progress-piechart.component';
import { PaymentsGraphComponent } from './ui/payments-progress-piechart/payments-graph/payments-graph.component';
import { PaymentsGraphLegendComponent } from './ui/payments-progress-piechart/payments-graph-legend/payments-graph-legend.component';
import { AddCommitmentModalComponent } from './ui/add-commitment-modal/add-commitment-modal.component';
import { QueryParamsFromStateDirective } from './core/directives/query-params-from-state.directive';
import { ConfirmTwoFaConnectionDialogComponent } from './ui/two-fa-modals/confirm-two-fa-connection/confirm-two-fa-connection-dialog.component';
import { TwoFaSuccessComponent } from './ui/two-fa-modals/two-fa-success/two-fa-success.component';
import { TwoFaCancelComponent } from './ui/two-fa-modals/two-fa-cancel/two-fa-cancel.component';
import { PdfEditDialogComponent } from './ui/pdf-edit-dialog/pdf-edit-dialog.component';
import { PdfSaveAsDialogComponent } from './ui/pdf-save-as-dialog/pdf-save-as-dialog.component';
import { EmailStatusIndicatorComponent } from './ui/email-status-indicator/email-status-indicator.component';
import { EarlyPayDemoDialogComponent } from './ui/early-pay-demo-dialog/early-pay-demo-dialog.component';
import { StatisticsInfoCardComponent } from './ui/info-card/statistics-info-card.component';
import {
  FinancialsSectionComponent,
} from './ui/company-profile-components/financials-section/financials-section.component';
import { EnvironmentalSafetyQuestionsComponent } from './ui/company-profile-components/environmental-safety-questions/environmental-safety-questions.component';
import { CompanyInsurancePoliciesComponent } from './ui/company-profile-components/company-insurance-policies/company-insurance-policies.component';
import { ProgressBarComponent } from './ui/progress-bar/progress-bar.component';
import { RegistrationSignComponent } from './ui/registration-sign/registration-sign.component';
import { ApprovalWorkflowPanelComponent } from './ui/approval-flow/approval-flow-panel/approval-workflow-panel.component';
import { ApprovalStepStatusIndicatorComponent } from './ui/approval-flow/approval-step-status-indicator/approval-step-status-indicator.component';
import { EarlyPayBannerComponent } from './ui/early-pay-banners/early-pay-banner/early-pay-banner.component';
import { EarlyPayTopBannerComponent } from './ui/early-pay-banners/early-pay-top-banner/early-pay-top-banner.component';
import { EarlyPayEmbeddedBannerComponent } from './ui/early-pay-banners/early-pay-embedded-banner/early-pay-embedded-banner.component';
import { EarlyPayBannerItemComponent } from './ui/early-pay-banners/early-pay-banner-item/early-pay-banner-item.component';
import { UnderscoreWordBreakDirective } from './core/directives/underscore-word-break.directive';
import { AttachmentsModalComponent } from './ui/attachments-modal/attachments-modal.component';
import { CounterpartSelectorComponent } from './ui/counterpart-selector/counterpart-selector.component';


export const currencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  nullable: true,
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
};

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    MatGoogleMapsAutocompleteModule,
    NgxMaskModule,
    NgxCurrencyModule.forRoot(currencyMaskConfig),
    SharedInitialModule,
  ],
  declarations: [
    AutocompleteDropdownComponent,
    FinancialsSectionComponent,
    ConfirmDialogComponent,
    CustomDropdownFilterComponent,
    CustomSearchDropdownFilterComponent,
    DateRangePickerComponent,
    CompanyDiversityCertificatesListComponent,
    DocumentsFileUploaderComponent,
    InformationDialogComponent,
    InviteCompanyBrokerDialogComponent,
    LocationSelectComponent,
    MultipleFileUploaderComponent,
    NewProjectSectionComponent,
    PieChartComponent,
    ProjectEndorsementsListComponent,
    ProjectExperiencesComponent,
    ProjectsFilterComponent,
    SendMessageDialogComponent,
    SplitWordsPipe,
    StatusPanelComponent,
    SubcontractorTierComponent,
    TableComponent,
    TexturaConnectDialogComponent,
    BrokerSectionComponent,
    BackToHeaderComponent,
    IconWithTooltipComponent,
    TableFiltersPanelComponent,
    FilePanelComponent,
    PlaidPanelComponent,
    StripePanelComponent,
    ColumnChartComponent,
    CompanyInsuranceLossHistoryComponent,
    CompanyInsuranceBondingLimitsComponent,
    CompanyInsurancePoliciesComponent,
    AddBrokerBlockComponent,
    ButtonLoadingDirective,
    PasswordStrengthAnalyzerComponent,
    TextLimitDirective,
    ProjectPaymentsProgressComponent,
    PaymentsProgressPiechartComponent,
    PaymentsGraphComponent,
    PaymentsGraphLegendComponent,
    AddCommitmentModalComponent,
    QueryParamsFromStateDirective,
    ConfirmTwoFaConnectionDialogComponent,
    TwoFaSuccessComponent,
    TwoFaCancelComponent,
    PdfEditDialogComponent,
    PdfSaveAsDialogComponent,
    EmailStatusIndicatorComponent,
    EarlyPayDemoDialogComponent,
    StatisticsInfoCardComponent,
    EnvironmentalSafetyQuestionsComponent,
    ProgressBarComponent,
    RegistrationSignComponent,
    ApprovalWorkflowPanelComponent,
    ApprovalStepStatusIndicatorComponent,
    EarlyPayBannerComponent,
    EarlyPayTopBannerComponent,
    EarlyPayEmbeddedBannerComponent,
    EarlyPayBannerItemComponent,
    UnderscoreWordBreakDirective,
    AttachmentsModalComponent,
    CounterpartSelectorComponent,
  ],
  exports: [
    AutocompleteDropdownComponent,
    FinancialsSectionComponent,
    ConfirmDialogComponent,
    CustomDropdownFilterComponent,
    CustomSearchDropdownFilterComponent,
    DateRangePickerComponent,
    CompanyDiversityCertificatesListComponent,
    CompanyInsurancePoliciesComponent,
    DocumentsFileUploaderComponent,
    InviteCompanyBrokerDialogComponent,
    LocationSelectComponent,
    MaterialModule,
    MultipleFileUploaderComponent,
    NavigationModule,
    NewProjectSectionComponent,
    NgxMaskModule,
    PieChartComponent,
    ProjectEndorsementsListComponent,
    ProjectExperiencesComponent,
    ProjectsFilterComponent,
    ReactiveFormsModule,
    RouterModule,
    SharedInitialModule,
    SplitWordsPipe,
    StatusPanelComponent,
    SubcontractorTierComponent,
    TableComponent,
    CompanyInsuranceLossHistoryComponent,
    CompanyInsuranceBondingLimitsComponent,
    BrokerSectionComponent,
    NgxCurrencyModule,
    BackToHeaderComponent,
    IconWithTooltipComponent,
    TableFiltersPanelComponent,
    FilePanelComponent,
    PlaidPanelComponent,
    StripePanelComponent,
    ColumnChartComponent,
    AddBrokerBlockComponent,
    ButtonLoadingDirective,
    PasswordStrengthAnalyzerComponent,
    TextLimitDirective,
    ProjectPaymentsProgressComponent,
    QueryParamsFromStateDirective,
    PdfEditDialogComponent,
    PdfSaveAsDialogComponent,
    EmailStatusIndicatorComponent,
    PaymentsProgressPiechartComponent,
    StatisticsInfoCardComponent,
    EnvironmentalSafetyQuestionsComponent,
    ProgressBarComponent,
    RegistrationSignComponent,
    ApprovalWorkflowPanelComponent,
    ApprovalStepStatusIndicatorComponent,
    EarlyPayBannerComponent,
    EarlyPayTopBannerComponent,
    EarlyPayEmbeddedBannerComponent,
    EarlyPayBannerItemComponent,
    UnderscoreWordBreakDirective,
    AttachmentsModalComponent,
    CounterpartSelectorComponent,
  ],
})
export class SharedModule {
}
