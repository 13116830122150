import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogSettings } from '../../ui/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  private isFirst = true;
  constructor(
    private matDialog: MatDialog,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i
      .test(window.navigator.userAgent);
    if (isMobile && this.isFirst) {
      this.matDialog.open<ConfirmDialogComponent, ConfirmDialogSettings>(ConfirmDialogComponent, {
        width: 'auto',
        data: {
          title: 'Sorry!',
          content: 'Unfortunately, this part of the site is not optimized for mobile devices. To make full use of all the features, ' +
            'visit the site from a desktop computer.\n' +
            'Our site works fine on browsers such as Google Chrome, Mozilla Firefox, or any other modern browser.',
          hideConfirmButton: true,
          dialogClassName: 'mobile-dialog',
        },
      });
      this.isFirst = false;
    }
    return true;
  }
}
