import { PubNubService } from '../core/services/pubnub.service';
import { FileService } from '../core/services/file.service';
import { Injectable } from '@angular/core';
import { v4 as uuid4 } from 'uuid';
import { Store } from '@ngrx/store';
import {
  PubNubChannel,
  PubNubEventInfoMessage,
  PubNubHistoryObject,
  PubNubMessage,
  PubNubMessageAuthor,
  PubNubMessageObject,
  PubNubMetaMessage,
  PubNubStatus,
} from '../models/chat.interface';
import { ChatInitialState } from './store/reducers/chat.reducer';
import {
  selectActiveCompanyChat,
  selectChannels,
  selectMessages,
  selectTotalUnreadMessages,
} from './store/selectors/chat.selectors';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  addChannel,
  addMessageToChannel,
  clearChannels,
  clearMessages,
  setActiveCompanyChat,
  setTotalUnreadMessages,
  updateChannel,
  updateChannels,
  updateMessageInChannel,
  updateMessages,
} from './store/actions/chat.actions';
import { User } from '../models/user';
import { UserService } from '../core/services/user.service';
import { NetworkService, TypedList } from '../core/api/network.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private newMessage$ = new Subject<{ channel: string; message: PubNubMessage }>();
  private currentUser: User;
  private unsubscribe$;

  constructor(
    private pubnubService: PubNubService,
    private store: Store<{ chat: ChatInitialState; user: any }>,
    private networkService: NetworkService,
    private fileService: FileService,
    private userService: UserService,
  ) {
  }

  simplifiedInit(): void {
    this.unsubscribe$ = new Subject();
    this.userService.getCurrentUser$().subscribe(user => {
      this.currentUser = user;
      const fullChannelList = [...user.chatrooms, user.notification_channel];
      this.pubnubService.subscribe(fullChannelList);
      const channels: PubNubChannel[] = [];
      user.chatrooms.forEach(c => channels.push({ title: c } as PubNubChannel));
      this.store.dispatch(updateChannels({ payload: { channels } }));
      this.updateChannelHistory(user.chatrooms);
      this.subscribeToChannelMessageUpdatesStatic(fullChannelList);
      setInterval(() => {
        this.calcTotalUnreadMessages.bind(this)();
      }, 17000);
    });
  }

  init(): void {
    this.unsubscribe$ = new Subject();
    this.userService.getCurrentUser$().subscribe(user => {
      this.currentUser = user;
    });

    this.getUserChannels().subscribe(data => {
      const channels = data.results;
      this.store.dispatch(updateChannels({ payload: { channels } }));
      const channelNames = channels.map(c => c.title);
      // Keep only existing `channelNames` (user's notification channel may not exists yet),
      // because `this.updateChannelHistory(channelNames)` will fail on not existing channels,
      // otherwise pubnub will throw an error.
      // But we can (and we _have_ to, in order to receive new messages from notification channel later)
      // subscribe to none existing (probably) yet channel, this will not throw an error
      this.pubnubService.subscribe([...channelNames, this.currentUser.notification_channel]);
      this.updateChannelHistory(channelNames);
      this.subscribeToChannelMessageUpdates();
    });
  }

  stop(): void {
    this.unsubscribe$?.next();
    this.unsubscribe$?.complete();
    this.pubnubService.unsubscribeAll();
    this.store.dispatch(clearChannels());
    this.store.dispatch(clearMessages());
    this.store.dispatch(setTotalUnreadMessages({ payload: { messageCount: 0 } }));
    this.currentUser = null;
    this.unsubscribe$ = null;
  }

  markLastSeenMessage(
    channelTitle: string,
    seenBy: PubNubMessageAuthor,
    lastMessage: PubNubMessage,
    returnObservable: boolean = false,
  ): Observable<{ status: PubNubStatus; message: PubNubMessage }> | void {
    const source$ = new Observable<{ status: PubNubStatus; message: PubNubMessage }>(observer => {
      const message = {
        author: seenBy,
        last_seen: lastMessage.id,
        last_seen_date: new Date().toISOString(),
      };
      this.pubnubService.publish(
        {
          channel: channelTitle,
          message,
          meta: null,
        },
        (status: PubNubStatus) => {
          observer.next({ status, message: (message as PubNubMessage) });
          observer.complete();
        },
      );
    });
    if (returnObservable) {
      return source$;
    }
    source$.subscribe();
  }

  getChannelLastMessage(channel: PubNubChannel, currentUser: User, isByChannelCompanion: boolean = false): PubNubMessage | null {
    const messages = this.messages[channel.title];
    if (messages === undefined || messages.length === 0) {
      return null;
    }
    let realMessages = messages.filter(m => !m.hasOwnProperty('last_seen')) as PubNubMessage[];
    if (isByChannelCompanion) {
      if (!currentUser) {
        return null;
      }
      const currentUserID = currentUser.id;
      realMessages = realMessages.filter(m => m.author.id !== currentUserID);
    }
    if (realMessages.length === 0) {
      return null;
    }
    return realMessages[realMessages.length - 1];
  }

  getChannelUnseenMessagesCount(channel: PubNubChannel, currentUser: User): number | null {
    const messages = this.messages[channel.title];
    if (messages === undefined || messages.length === 0) {
      return null;
    }

    if (!currentUser) {
      return null;
    }
    const currentUserID = currentUser.id;
    const realMessages = messages.filter(m => !m.hasOwnProperty('last_seen')) as PubNubMessage[];
    const messagesByChannelCompanion = realMessages.filter(m => m.author.id !== currentUserID);
    const lastSeenMessage = messages
      .filter(m => m.hasOwnProperty('last_seen'))
      .reverse()
      .find(m => m.author.id === currentUserID) as PubNubMetaMessage;
    if (lastSeenMessage === undefined) {
      if (
        this.getChannelLastMessage(channel, currentUser) === null ||
        this.getChannelLastMessage(channel, currentUser).author.id === currentUserID
      ) {
        return null;
      }
      return messagesByChannelCompanion.length;
    }

    const lastMessageIndexSeenByUser = messagesByChannelCompanion.findIndex(m => m.id === lastSeenMessage.last_seen);
    return messagesByChannelCompanion.length - (lastMessageIndexSeenByUser + 1);
  }

  sendMessage(
    channelTitle: string,
    author: PubNubMessageAuthor,
    text: string,
  ): Observable<{ status: PubNubStatus; message: PubNubMessage }> {
    return new Observable<{ status: PubNubStatus; message: PubNubMessage }>(observer => {
      const message = {
        id: uuid4(),
        text,
        author,
        date_published: new Date().toISOString(),
      };
      this.pubnubService.publish(
        {
          channel: channelTitle,
          message,
        },
        (status: PubNubStatus) => {
          if (!status.error) {
            this.store.dispatch(
              addMessageToChannel({
                payload: { channelName: channelTitle, message },
              }),
            );
            this.newMessage$.next({ channel: channelTitle, message });
          }
          observer.next({ status, message });
          observer.complete();
        },
      );
    });
  }

  sendFile(channelTitle: string, author: PubNubMessageAuthor, file: any): void {
    let message = {
      id: uuid4(),
      file,
      author,
      upload: true,
      date_published: new Date().toISOString(),
    };
    this.directAddMessage(channelTitle, message);
    this.fileService.uploadFile(file).subscribe(res => {
      message = { ...message, file: { link: res.file, name: res.name, size: res.size }, upload: false };
      this.pubnubService.publish(
        {
          channel: channelTitle,
          message: { ...message },
        },
        (status: PubNubStatus) => {
          if (!status.error) {
            this.directUpdateMessage(channelTitle, message);
          }
        },
      );
    });
  }

  private directAddMessage(channel: string, message: PubNubMessage): void {
    this.newMessage$.next({ channel, message });
    this.store.dispatch(
      addMessageToChannel({
        payload: { channelName: channel, message },
      }),
    );
  }

  private directUpdateMessage(channel: string, message: PubNubMessage): void {
    this.newMessage$.next({ channel, message });
    this.store.dispatch(
      updateMessageInChannel({
        payload: { channelName: channel, message },
      }),
    );
  }

  get messages(): { [p: string]: Array<PubNubMessage | PubNubMetaMessage> } {
    let messages: ChatInitialState['messages'];
    this.getMessagesFromStore$()
      .pipe(take(1))
      .subscribe(s => (messages = s));
    return messages;
  }

  get activeCompanyChat(): number {
    let activeCompanyChat: ChatInitialState['activeCompanyChat'];
    this.getActiveCompanyChat$()
      .pipe(take(1))
      .subscribe(s => (activeCompanyChat = s));
    return activeCompanyChat;
  }

  get channels(): PubNubChannel[] {
    let channels: ChatInitialState['channels'];
    this.getChannelsFromStore$()
      .pipe(take(1))
      .subscribe(s => (channels = s));
    return channels;
  }

  private async updateChannelHistory(channelNames: string[] | string): Promise<void> {
    if (typeof channelNames === 'string') {
      const history = await this.pubnubService.history(channelNames).toPromise() as PubNubHistoryObject;
      const messages = history.messages.map(m => m.entry);
      this.store.dispatch(updateMessages({ payload: { channel: channelNames, messages } }));
    } else {
      const promises = channelNames.map(async channel => {
        const history = await this.pubnubService.history(channel).toPromise() as PubNubHistoryObject;
        const messages = history.messages.map(m => m.entry);
        this.store.dispatch(updateMessages({ payload: { channel, messages } }));
      });
      await Promise.all(promises);
    }
  }

  addChannel(channel: PubNubChannel): void {
    this.pubnubService.subscribe([channel.title]);
    this.store.dispatch(addChannel({ payload: { channel } }));
  }

  private subscribeToChannelMessageUpdates(): void {
    this.store
      .select(selectChannels)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(channels => {
        const channelNames = channels.map(c => c.title);
        channelNames.push(this.currentUser.notification_channel);
        this.subscribeToChannelMessageUpdatesStatic(channelNames);
      });
  }

  private subscribeToChannelMessageUpdatesStatic(channelNames: string[]): void {
    this.pubnubService.getMessage(channelNames, (msg: PubNubMessageObject) => {
      const messageBody = msg.message as PubNubEventInfoMessage;
      if (messageBody.event) {
        if (messageBody.event === 'new_channel') {
          const channel = messageBody.data as PubNubChannel;
          this.addChannel(channel);
          this.updateChannelHistory(channel.title);
        } else if (messageBody.event === 'update_channel') {
          const channel = messageBody.data as PubNubChannel;
          this.store.dispatch(updateChannel({ payload: { channel } }));
        }
      } else {
        const message = msg.message as PubNubMessage;
        this.store.dispatch(
          addMessageToChannel({
            payload: { channelName: msg.channel, message },
          }),
        );
        this.newMessage$.next({ channel: msg.channel, message });
      }
    });
  }

  onNewMessage$(): Observable<{ channel: string; message: PubNubMessage }> {
    return this.newMessage$.asObservable();
  }

  private getMessagesFromStore$(): Observable<{ [p: string]: Array<PubNubMessage | PubNubMetaMessage> }> {
    return this.store.select(selectMessages);
  }

  private getChannelsFromStore$(): Observable<PubNubChannel[]> {
    return this.store.select(selectChannels);
  }

  private getActiveCompanyChat$(): Observable<number> {
    return this.store.select(selectActiveCompanyChat);
  }

  getTotalUnreadMessages$(): Observable<number> {
    return this.store.select(selectTotalUnreadMessages);
  }

  private getUserChannels(): Observable<TypedList<PubNubChannel>> {
    return this.networkService.get<TypedList<PubNubChannel>>(['chat', 'chats']);
  }

  setActiveCompanyChat(companyId: number): void {
    this.store.dispatch(setActiveCompanyChat({ payload: { companyId } }));
  }

  startChatByCompanyId(companyId: number): Observable<PubNubChannel> {
    this.setActiveCompanyChat(companyId);
    return this.networkService.post<PubNubChannel>(['chat', 'chats'], { to_company: companyId });
  }

  resetActiveCompanyChat(): void {
    this.store.dispatch(setActiveCompanyChat({ payload: { companyId: null } }));
  }

  private calcTotalUnreadMessages(): void {
    if (this.currentUser) {
      this.store
        .select(selectChannels)
        .subscribe(channels => {
          const unreadMessageCount = channels.filter(channel => !channel.companies || channel.companies.length >= 2)
            .map(channel => this.getChannelUnseenMessagesCount(channel, this.currentUser))
            .filter(count => count)
            .reduce((sum, current) => sum + current, 0);
          this.store.dispatch(setTotalUnreadMessages({ payload: { messageCount: unreadMessageCount } }));
        });
    }
  }
}
