type RequestMethod = 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS' | 'POST' | 'PATCH';

export enum ResponseStateEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface BaseMockResponse {
  method: RequestMethod;
  path: string;
}

interface SuccessfulMockResponse extends BaseMockResponse {
  state: ResponseStateEnum.SUCCESS;
  response: { status: number; body: any };
}

interface FailedMockResponse extends BaseMockResponse {
  state: ResponseStateEnum.ERROR;
  error: { status: number, error: any };
}

export type MockResponse = SuccessfulMockResponse | FailedMockResponse;
