import { Action, createReducer, on } from '@ngrx/store';
import { setAdminAlertsItems } from './actions';
import { AdminAlertsState } from '../types';


export const initialState: AdminAlertsState = {
  items: [],
};

const reducer = createReducer(
  initialState,
  on(setAdminAlertsItems, (state: AdminAlertsState, { payload }) => {
    return { ...state,  items: payload};
  }),
);

export function adminAlertsReducer(state: AdminAlertsState, action: Action): AdminAlertsState {
  return reducer(state, action);
}
