import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { AdminAlertsState } from '../types';
import { Store } from '@ngrx/store';
import { AdminAlert } from '../interfaces/admin-alert';
import { selectAdminAlertsItems } from '../store/selectors';
import { fetchAdminAlerts, hideAdminAlert } from '../store/actions';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../../../local-storage/local-storage.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { UserService } from '../../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAlertsService {
  static readonly POOLING_INTERVAL = 10 * 60 * 1000; // 10 minutes

  constructor(
    private store: Store<{ adminAlerts: AdminAlertsState }>,
    private localStorageService: LocalStorageService,
    private userService: UserService,
  ) {
    this.maintainLocalStorage();
  }

  getItems(): Observable<AdminAlert[]> {
    return this.store.select(selectAdminAlertsItems).pipe(
      map(alerts => alerts.filter(alert => !this.isAlertHidden(alert.id))),
    );
  }

  fetchAlerts(): void {
    this.userService.getCurrentUser$().subscribe(user => {
      if (user) {
        this.store.dispatch(fetchAdminAlerts());
      }
    });

  }

  startPulling(): Observable<number> {
    return timer(0, AdminAlertsService.POOLING_INTERVAL).pipe(
      tap(() => this.fetchAlerts()),
    );
  }

  hideAlert(id: string): void {
    const hiddenAlerts = this.localStorageService.get('hiddenAlerts') as string[] || [];
    if (!hiddenAlerts.includes(id)) {
      hiddenAlerts.push(id);
    }
    this.localStorageService.set('hiddenAlerts', hiddenAlerts);
    this.store.dispatch(hideAdminAlert({ payload: id }));
  }

  private isAlertHidden(id: string): boolean {
    const hiddenAlerts = this.localStorageService.get('hiddenAlerts') as string[] || [];
    return hiddenAlerts.includes(id);
  }

  private maintainLocalStorage(): void {
    const cleanAlertsDate = this.localStorageService.get('cleanAlertsDate');
    if (!cleanAlertsDate) {
      this.localStorageService.set('cleanAlertsDate', moment().add(7, 'days'));
    } else if (moment().isAfter(cleanAlertsDate)) {
      this.localStorageService.set('hiddenAlerts', []);
      this.localStorageService.set('cleanAlertsDate', moment().add(7, 'days'));
    }
  }
}
