export const environment = {
  production: false,
  staging: false,
  HOST_URL: 'https://playground.constrafor.com/',
  API_URL: 'https://api-playground.constrafor.com',
  WS_URL: 'wss://api-playground.constrafor.com/ws',
  pubnubSubKey: 'sub-c-e8df9512-5422-11ea-b828-26d2a984a2e5',
  pubnubPubKey: 'pub-c-adf93e89-a9fa-481c-8533-36f923667727',
  helloSignClient: '20291aab58ec7f4c8590aa0751309110',
  PLAID_ENV: 'sandbox',
  pdfEmbedApiKey: '43df98500c0445d7a7566bd540216f31',
};
