<section class="admin-alert admin-alert--{{ level }}">
  <span markdown [data]="message" class="admin-alert__message"></span>
  <button
    *ngIf="isClosable"
    class="admin-alert__close-button"
    aria-label="Close"
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</section>
