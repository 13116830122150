import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../window/window.token';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor(
    @Inject(WINDOW) private window: Window,
  ) {
  }

  set(key: string, value: any): void {
    const preparedValue = JSON.stringify(value);
    this.window.localStorage.setItem(key, preparedValue);
  }

  get<T>(key: string): T {
    const value = this.window.localStorage.getItem(key);
    return JSON.parse(value);
  }

  remove(key: string): void {
    this.window.localStorage.removeItem(key);
  }

  clear(): void {
    this.window.localStorage.clear();
  }
}
