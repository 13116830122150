import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { dialogCloseAnimation, dialogCompleteAnimation } from 'src/app/core/animations/dialog';
import { CDialogComponent } from 'src/app/core/cdialog/cdialog.component';

export interface ConfirmDialogSettings {
  title: string;
  content: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  hideConfirmButton?: boolean;
  showCancelButton?: boolean;
  dialogClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  animations: [
    dialogCloseAnimation,
    dialogCompleteAnimation,
  ],
})
export class ConfirmDialogComponent extends CDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogSettings: ConfirmDialogSettings,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
    super(dialogRef);
  }

  onCancel(): void {
    this.dialog.closeDialog();

    if (this.dialogSettings?.onCancel) {
      this.dialogSettings.onCancel();
    }
  }

  onConfirm(): void {
    this.dialogRef.close({ success: true });

    if (this.dialogSettings?.onConfirm) {
      this.dialogSettings.onConfirm();
    }
  }
}
