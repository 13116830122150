import { PubNubService } from './pubnub.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UUID } from '../../models/id';


export interface NotificationItem {
  title: string;
  subtitle: string;
  action?: () => void;
  timetoken: number;
  event: string;
  id: UUID;
}

interface NotificationMessage {
  timetoken: number;
  entry: {
    event: string;
    data: any;
    id?: UUID;
  };
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationChannel: string;
  private notifications$: BehaviorSubject<NotificationItem[]> = new BehaviorSubject<NotificationItem[]>([]);
  private unreadNotificationsCount$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private lastSeenNotification: NotificationMessage;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private pubnubService: PubNubService,
    private userService: UserService,
    private router: Router,
    private titleCasePipe: TitleCasePipe,
  ) {
  }

  init(): void {
    this.userService.getCurrentUser$().subscribe(user => {
      this.notificationChannel = user.notification_channel;
    });

    this.fetchNotificationsFromPubnubHistory(this.notificationChannel).subscribe(notifications => {
      const reversedNotifications = [...notifications].reverse();
      this.notifications$.next(reversedNotifications);
    });

    this.subscribeToPubnubNotifications().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(notification => {
      this.notifications$.next([
        notification,
        ...this.notifications$.getValue(),
      ]);
    });

    this.notifications$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.unreadNotificationsCount$.next(this.getUnreadNotificationsCount());
    });
  }

  private fetchNotificationsFromPubnubHistory(notificationChannel: string): Observable<NotificationItem[]> {
    return this.pubnubService.history(notificationChannel).pipe(
      tap(history => {
        const lastSeenNotifications = history.messages.filter(message => message.entry.event === 'notification_seen');
        if (lastSeenNotifications.length > 0) {
          this.lastSeenNotification = lastSeenNotifications[lastSeenNotifications.length - 1];
        }
      }),
      map(history => {
        return history.messages
          .map(notification => this.parseNotification(notification) || null)
          .filter(notification => notification);
      }),
    );
  }

  private subscribeToPubnubNotifications(): Observable<NotificationItem> {
    const subject = new Subject<NotificationItem>();

    this.pubnubService.getMessage([this.notificationChannel], (messageObject) => {
      const message = {
        timetoken: Number(messageObject.timetoken),
        entry: {
          ...messageObject.message,
        },
      } as NotificationMessage;

      if (message.entry.event === 'notification_seen') {
        this.lastSeenNotification = message;
        this.unreadNotificationsCount$.next(this.getUnreadNotificationsCount());
      }

      const parsedNotification = this.parseNotification(message);
      if (parsedNotification) {
        subject.next(parsedNotification);
      }
    });

    return subject.asObservable();
  }

  stop(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getNotifications(): Observable<NotificationItem[]> {
    return this.notifications$.asObservable();
  }

  getUnseenNotificationsCount(): Observable<string> {
    return this.unreadNotificationsCount$.asObservable();
  }

  private getUnreadNotificationsCount(): string {
    const notifications = this.notifications$.getValue();

    if (notifications.length === 0) {
      return '';
    }

    const lastSeenTimetoken = this.lastSeenNotification ? this.lastSeenNotification.timetoken : 0;
    const notificationCount = notifications
      .filter(({ timetoken }) => timetoken > lastSeenTimetoken)
      .length;

    return notificationCount === 0 ? '' : String(notificationCount);
  }

  private parseNotification(notification: NotificationMessage): NotificationItem | undefined {
    const { timetoken, entry: { data, event, id } } = notification;
    let parsedNotification;
    switch (event) {
      case 'invite_to_supply_request':
        parsedNotification = {
          title: 'New invitation',
          subtitle: `Contractor "${data.supply_request.contractor_name}" invited you to request "${data.supply_request.name}"`,
          action: () => this.router.navigate(['requests'], { queryParams: { id: data.supply_request.id } }),
        };
        break;
      case 'company_accepted_invite':
        const companyType = this.prettifyCompanyType(data.company_type);
        parsedNotification = {
          title: 'Invitation accepted',
          subtitle: `${companyType} "${data.name}" accepted your invitation to Constrafor."`,
        };
        break;
      case 'contractor_create_insurance_request':
        parsedNotification = {
          title: 'New request',
          subtitle: `Contractor "${data.contractor_name}" invited you to submit policy ` +
            `${this.titleCasePipe.transform(data.product_type?.split('-').join(' '))} on "${data.project_name}"`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'contractor_approved_insurance_request':
        parsedNotification = {
          title: 'Request approved',
          subtitle: `Your COI for "${data.contractor_name}" project "${data.project_name}" has been approved.`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'contractor_rejected_insurance_request':
        parsedNotification = {
          title: 'Request rejected',
          subtitle: `Your COI for "${data.contractor_name}" project "${data.project_name}" ` +
            `has been rejected due to ${data.note}`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'contractor_rejected_insurance_request_v2':
        parsedNotification = {
          title: 'Request rejected',
          subtitle: `Some of your COIs for "${data.gc_name}" project "${data.project_name}" ` +
            `have been rejected`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'contractor_send_reminder':
        parsedNotification = {
          title: 'Reminder',
          subtitle: `"${data.contractor_name}" is reminding you to update your ` +
            `${this.titleCasePipe.transform(data.product_type?.split('-').join(' '))} policy to "${data.project_name}".`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'contractor_invite_subcontractor':
        parsedNotification = {
          title: 'New invitation',
          subtitle: `Contractor "${data.contractor_name}" invited you to subcontractors`,
        };
        break;
      case 'contractor_expiring_request':
        parsedNotification = {
          title: 'Expiry reminder',
          subtitle: `${data.expiry_count} certifications expired today`,
          action: () => this.router.navigate([
            'certificates-of-insurance',
            'certificates-page',
            'certificates',
          ], { queryParams: { expiring_after: data.today, expiring_before: data.today } }),
        };
        break;
      case 'subcontractor_expiring_request':
        parsedNotification = {
          title: 'Expiry reminder',
          subtitle: `Your COI for ${data.contractor_name} project ${data.project_name} ` +
            `is expiring within ${data.expiry_days} days. Please make sure to renew.`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'subcontractor_expiring_request_today':
        parsedNotification = {
          title: 'Expiry reminder',
          subtitle: `Your COI for ${data.contractor_name} project ${data.project_name} ` +
            `has expired. Please renew!`,
          action: () => this.router.navigate(
            ['subcontractor-insurance', 'coi-manager', data.project_id],
          ),
        };
        break;
      case 'subcontractor_update_files':
        parsedNotification = {
          title: 'Request updated',
          subtitle: `"${data.subcontractor_name}" uploaded ` +
            `${this.titleCasePipe.transform(data.product_type?.split('-').join(' '))} policy to "${data.project_name}".`,
          action: () => this.router.navigate([
            'certificates-of-insurance',
            'certificates-page',
            'certificates',
            data.insurance_request_id,
          ]),
        };
        break;
      case 'contract_was_signed':
        parsedNotification = {
          title: 'Contract signed',
          subtitle: `${data.contractor_name || data.from_company_name} signed the ${data.contract_name} contract for ${data.project_name}`,
          action: () => this.router.navigate([
            'contracts',
            'view-contract',
            data.contract_id,
          ]),
        };
        break;
      case 'contract_was_sent':
        parsedNotification = {
          title: 'Contract sent',
          subtitle: `${data.contractor_name || data.from_company_name} shared a contract ${data.contract_name} for ${data.project_name} with you.`,
          action: () => this.router.navigate([
            'contracts',
            'view-contract',
            data.contract_id,
          ]),
        };
        break;
      case 'contract_was_updated':
        parsedNotification = {
          title: 'Contract updated',
          subtitle: `${data.contractor_name || data.from_company_name} has updated the ${data.contract_name} contract for ${data.project_name}`,
          action: () => this.router.navigate([
            'contracts',
            'view-contract',
            data.contract_id,
          ]),
        };
        break;
      case 'invoice_created_gc':
        parsedNotification = {
          title: 'New Invoice',
          subtitle: `You have a new invoice for project ${data.project}`,
          action: () => this.router.navigate([
            'invoicing', 'invoice-management', data.invoice_id,
          ]),
        };
        break;
      case 'invoice_status_changed':
        parsedNotification = {
          title: 'Your invoice status has changed',
          subtitle: `The status of your invoice for project ${data.project} has changed to ${data.new_status}`,
          action: () => this.router.navigate([
            'invoicing', 'invoice-management', data.invoice_id,
          ]),
        };
        break;
      case 'invoice_new_payment':
        parsedNotification = {
          title: 'New payment',
          subtitle: `You received a new payment for the invoice for project ${data.project}`,
          action: () => this.router.navigate([
            'invoicing', 'invoice-management', data.invoice_id,
          ]),
        };
        break;
      default:
        return undefined;
    }

    return { ...parsedNotification, timetoken, event, id };
  }

  private prettifyCompanyType(name: string = ''): string {
    if (name.length) {
      const newName = name.replace('_', ' ');
      return newName.charAt(0).toUpperCase() + newName.slice(1);
    }

    return name || 'Company';
  }

  markNotificationAsSeen(): void {
    const notifications = this.notifications$.getValue();
    if (notifications.length > 0 && notifications[0].id !== this.lastSeenNotification?.entry.data.id) {
      this.pubnubService.publish({
        channel: this.notificationChannel,
        message: {
          event: 'notification_seen',
          data: {
            id: notifications[0].id,
          },
        },
        meta: null,
      });
    }
  }

  removeNotification(notification: NotificationItem): void {
    if (this.notificationChannel) {
      const notifications = this.notifications$.getValue();
      const index = notifications.findIndex(({ id }) => id === notification.id);
      const formattedNotifications = [...notifications.slice(0, index), ...notifications.slice(index + 1)];
      this.notifications$.next(formattedNotifications);

      this.pubnubService.deleteMessage(this.notificationChannel, String(notification.timetoken));
    }
  }

  removeAllNotifications(): void {
    if (this.notificationChannel) {
      const timetokenValues = this.notifications$.getValue().map(({ timetoken }) => timetoken);
      const start = Math.min(...timetokenValues) - 2;
      const end = Math.max(...timetokenValues) + 2;
      this.notifications$.next([]);

      this.pubnubService.deleteMessages(this.notificationChannel, String(start), String(end));
    }
  }

}
