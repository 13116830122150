import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck, startWith, switchMap, tap } from 'rxjs/operators';

import { UserService } from './user.service';
import { User } from '../../models/user';
import { Address } from '../../models/address';
import { TeamMemberInvite } from '../../models/team-member-invite';
import { Roles } from '../../models/roles';
import { ConstructionType } from '../../models/construction-type';
import { BrokerInvite } from '../../models/broker-invite';
import { Company } from '../../models/company';
import { CreatePaymentsRequest, GenericPayment, PaymentSendingStatus, UnionPayment } from '../../models/payments';
import { Invoice, InvoiceHistory, PaymentData } from '../../models/invoice';
import { ID, UUID } from '../../models/id';
import { InvoiceReport } from '../../models/invoice-report';
import { DiversityCertification } from '../../models/diversity-certification';
import { Certification } from '../../models/certification';
import { EarlyPayInfo } from '../../models/early-pay-info';
import { Project } from '../../models/project';
import { projectDetailsUpdated } from '../../projects/store/projects.actions';
import { Store } from '@ngrx/store';
import { ProjectExperience } from '../../models/project-experience';
import { Endorsement } from '../../models/endorsement';
import { HealthSafetyQuestion } from '../../models/health-safety-question';
import { Policy } from '../../models/policy';
import { EarlyPayProgram } from '../../models/early-pay-program';
import {
  CompanyAttachment,
  CompanyComment,
  CompanyScore,
  CompanyTrade,
  TradesToConsider,
} from '../../models/internal-notes';

import { ExportRequest, ExportRequestSubtype } from '../../models/export-file-model';
import { NetworkService, Params, TypedList } from '../api/network.service';
import { PaymentProgress, PaymentProgressCreated } from '../../models/payment-progress';
import { EmailValidityInfo } from '../../models/email-validity-info';
import { ContractRule, WorkflowRule, WorkflowRulePayload, WorkflowTableRow } from '../../models/workflow-rule';
import { ApprovalStepStatuses } from '../../models/workflow';
import { Contract } from '../../models/contract';
import { DropdownOption } from '../../models/dropdown-option';
import { updateCompanyProfile } from '../../company-profile/store/company-profile.actions';


@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private userService: UserService,
    private networkService: NetworkService,
    private store: Store,
  ) {
  }

  getUserCompany(): Observable<Company> {
    return this.userService.getCurrentUser$().pipe(
      switchMap(user => {
        return this.networkService.get<Company>(['constrafor', 'companies', user.company_id, 'profile']);
      }),
    );
  }

  getContractorProfile(id: ID | string): Observable<Company> {
    return this.networkService.get<Company>(['constrafor', 'contractors', 'profile', id]);
  }

  updateUserCompany(id: ID, company: any, isOnboarding?: boolean): Observable<Company> {
    const params = isOnboarding ? { onboarding: 'true' } : {};
    return this.networkService.patch<Company>(['constrafor', 'companies', id, 'profile', params], company).pipe(
      tap(updatedCompany => this.store.dispatch(updateCompanyProfile({ payload: updatedCompany }))),
    );
  }

  inviteTeamMember(userInfo: TeamMemberInvite): Observable<any> {
    return this.networkService.post<any>(['constrafor', 'companies', 'team'], userInfo);
  }

  removeTeamMember(userId: ID): Observable<any> {
    return this.networkService.delete<any>(['constrafor', 'companies', 'team', userId]);
  }

  getCompanyUsers(params?: Params): Observable<TypedList<User>> {
    return this.networkService.get<TypedList<User>>(['constrafor', 'companies', 'team', params]);
  }

  getRoles(): Observable<Roles> {
    return this.networkService.get<Roles>(['users', 'roles']);
  }

  changeUserRole(user: User, role: number): Observable<User> {
    return this.networkService.patch<User>(['constrafor', 'companies', 'team', user.id], { role });
  }

  changeManagesCompanies(user: User, managesCompanies: number[]): Observable<User> {
    return this.networkService.patch<User>(['constrafor', 'companies', 'team'], {
      manages_companies: managesCompanies,
      role: user.role,
      user: user.id,
    });
  }

  getConstructionTypes(): Observable<ConstructionType[]> {
    return this.networkService.get<TypedList<ConstructionType>>(['constrafor', 'construction-types']).pipe(
      pluck('results'),
    );
  }

  patchAddress(id: ID, address: Partial<Address>): Observable<Address> {
    return this.networkService.patch<Address>(['constrafor', 'addresses', id], address);
  }

  inviteBroker(brokerEmail: string): Observable<BrokerInvite> {
    return this.networkService.post<BrokerInvite>(
      ['constrafor', 'brokers', 'management'],
      { contact_email: brokerEmail },
    );
  }

  removeBroker(companyId: ID): Observable<Company> {
    return this.networkService.delete<Company>(['constrafor', 'companies', companyId, 'broker']);
  }

  getBrokerInvites(): Observable<BrokerInvite[]> {
    return this.networkService.get<TypedList<BrokerInvite>>(['constrafor', 'brokers', 'management']).pipe(
      pluck('results'),
    );
  }

  acceptBrokerInvite(inviteId: ID): Observable<BrokerInvite> {
    return this.networkService.patch<BrokerInvite>(['constrafor', 'brokers', 'management', inviteId]);
  }

  declineBrokerInvite(inviteId: ID): Observable<void> {
    return this.networkService.delete<void>(['constrafor', 'brokers', 'management', inviteId]);
  }

  makePayments(paymentsData: CreatePaymentsRequest): Observable<PaymentSendingStatus> {
    return this.networkService.post<PaymentSendingStatus>(['payments', 'direct-payments'], paymentsData);
  }

  getInvoices(params?: Params): Observable<TypedList<Invoice>> {
    return this.networkService.get<TypedList<Invoice>>(['payments', 'invoices', params]);
  }

  getPaymentProgress(params?: Params): Observable<PaymentProgress> {
    return this.networkService.get<PaymentProgress>(['payments', 'commitments', params]);
  }

  setProjectCommitment(data: { subcontractor: ID, project: ID, amount: number }): Observable<PaymentProgressCreated> {
    return this.networkService.post<PaymentProgressCreated>(['payments', 'commitments'], data);
  }

  getInvoice(uuid: UUID): Observable<Invoice> {
    return this.networkService.get<Invoice>(['payments', 'invoices', uuid]);
  }

  getEarlyPayInfo(invoiceId: UUID): Observable<EarlyPayInfo> {
    return this.networkService.get<EarlyPayInfo>(['payments', 'invoices', invoiceId, 'early_pay']);
  }

  getEarlyPayProgramDetails(id: ID): Observable<EarlyPayProgram> {
    return this.networkService.get<EarlyPayProgram>(['payments', 'early-pay', id]);
  }

  cancelEarlyPayProgram(id: ID): Observable<EarlyPayProgram> {
    return this.networkService.delete<EarlyPayProgram>(['payments', 'early-pay', id]);
  }

  getEarlyPayPrograms(params?: Params): Observable<TypedList<EarlyPayProgram>> {
    return this.networkService.get<TypedList<EarlyPayProgram>>(['payments', 'early-pay', params]);
  }

  confirmEarlyPay(invoiceId: UUID, forced: boolean = false): Observable<EarlyPayProgram> {
    const data = forced ? { forced } : null;
    return this.networkService.post<EarlyPayProgram>(['payments', 'invoices', invoiceId, 'early_pay'], data);
  }

  createInvoice(invoice: Partial<Invoice>): Observable<Invoice> {
    return this.networkService.post<Invoice>(['payments', 'invoices'], invoice);
  }

  patchInvoice(id: UUID, invoice: Partial<Invoice>): Observable<Invoice> {
    return this.networkService.patch<Invoice>(['payments', 'invoices', id], invoice);
  }

  payInvoice(paymentData: PaymentData): Observable<PaymentData> {
    return this.networkService.post<PaymentData>(['payments', 'invoice-payments'], paymentData);
  }

  getAllPayments(params?: Params): Observable<TypedList<GenericPayment>> {
    return this.networkService.get<TypedList<GenericPayment>>(['payments', 'payments', params]);
  }

  getPayment(id: ID): Observable<UnionPayment> {
    return this.networkService.get<UnionPayment>(['payments', 'payments', id]);
  }

  getInvoiceStatistics(params?: Params): Observable<InvoiceReport> {
    return this.networkService.get<InvoiceReport>(['payments', 'invoices', 'insights', params]);
  }

  getInvoiceHistory(id: UUID): Observable<InvoiceHistory[]> {
    return this.networkService.get<InvoiceHistory[]>(['payments', 'invoices', id, 'history']);
  }

  updateInvoiceApprovalWorkflowStepStatus(invoiceId: UUID, status: ApprovalStepStatuses, step_id: ID): Observable<Contract> {
    return this.networkService.post<Contract>(['payments', 'invoices', invoiceId, 'steps'], { status, step_id });
  }

  getDiversityCertification(id: ID): Observable<DiversityCertification> {
    return this.networkService.get<DiversityCertification>(['diversity', 'diversity_certifications', id]);
  }

  createDiversityCertification(data: DiversityCertification): Observable<DiversityCertification> {
    return this.networkService.post<DiversityCertification>(['diversity', 'diversity_certifications'], data);
  }

  updateDiversityCertification(id: ID, data: DiversityCertification): Observable<DiversityCertification> {
    return this.networkService.patch<DiversityCertification>(['diversity', 'diversity_certifications', id], data);
  }

  deleteDiversityCertification(id: ID): Observable<any> {
    return this.networkService.delete<Company>(['diversity', 'diversity_certifications', id]);
  }

  getCertifications(params?: Params): Observable<Certification[]> {
    return this.networkService.get<TypedList<Certification>>(['constrafor', 'certifications', params]).pipe(
      pluck('results'),
    );
  }

  assignFieldOpsToProject(userIds: ID[], projectId: ID): Observable<Project> {
    return this.networkService.put<Project>(['constrafor', 'companies', 'team', 'assign_access_project'], {
      user_ids: userIds,
      project_id: projectId,
    }).pipe(
      tap((project) => {
        this.store.dispatch(projectDetailsUpdated({
          payload: project,
        }));
      }),
    );
  }

  removeFieldOpsFromProject(userId: ID, projectId: ID): Observable<Project> {
    return this.networkService.put<Project>(
      ['constrafor', 'companies', 'team', userId, 'remove_access_project'],
      { project_id: projectId },
    ).pipe(
      tap((project) => {
        this.store.dispatch(projectDetailsUpdated({
          payload: project,
        }));
      }),
    );
  }

  updateCompanyExperience(id: ID, data: ProjectExperience[]): Observable<Company> {
    return this.networkService.put<Company>(['constrafor', 'companies', id, 'profile', 'experience'], data).pipe(
      tap(updatedCompany => this.store.dispatch(updateCompanyProfile({ payload: updatedCompany }))),
    );
  }

  addProjectEndorsement(id: ID, data: Endorsement): Observable<Company> {
    return this.networkService.post<Company>(['constrafor', 'companies', id, 'profile', 'endorsements'], data);
  }

  updateProjectEndorsement(companyId: ID, endorsementId: ID, data: Endorsement): Observable<Company> {
    return this.networkService.patch<Company>(
      ['constrafor', 'companies', companyId, 'profile', 'endorsements', endorsementId],
      data,
    );
  }

  deleteProjectEndorsement(companyId: ID, endorsementId: ID): Observable<Company> {
    return this.networkService.delete<Company>(
      ['constrafor', 'companies', companyId, 'profile', 'endorsements', endorsementId],
    );
  }

  updateHealthSafety(id: ID, data: HealthSafetyQuestion[]): Observable<Company> {
    return this.networkService.put<Company>(['constrafor', 'companies', id, 'profile', 'health_safety'], data).pipe(
      tap(updatedCompany => this.store.dispatch(updateCompanyProfile({ payload: updatedCompany }))),
    );
  }

  addCompanyPolicy(id: ID, data: Partial<Policy>): Observable<Company> {
    return this.networkService.post<Company>(['constrafor', 'companies', id, 'profile', 'policies'], data);
  }

  updateCompanyPolicy(companyId: ID, policyId: ID, data: Partial<Policy>): Observable<Company> {
    return this.networkService.patch<Company>(
      ['constrafor', 'companies', companyId, 'profile', 'policies', policyId],
      data,
    );
  }

  deleteCompanyPolicy(companyId: ID, policyId: ID): Observable<Company> {
    return this.networkService.delete<Company>(
      ['constrafor', 'companies', companyId, 'profile', 'policies', policyId],
    );
  }

  getCompanyComments(id: ID, params: Params): Observable<TypedList<CompanyComment>> {
    return this.networkService.get<TypedList<CompanyComment>>(
      ['constrafor', 'companies', id, 'profile_notes', 'comments', params],
    );
  }

  addCompanyComment(id: ID, data: CompanyComment): Observable<TypedList<CompanyComment>> {
    return this.networkService.post<TypedList<CompanyComment>>(
      ['constrafor', 'companies', id, 'profile_notes', 'comments'],
      data,
    );
  }

  updateCompanyComment(companyId: ID, commentId: ID, data: CompanyComment): Observable<TypedList<CompanyComment>> {
    return this.networkService.patch<TypedList<CompanyComment>>(
      ['constrafor', 'companies', companyId, 'profile_notes', 'comments', commentId],
      data,
    );
  }

  deleteCompanyComment(companyId: ID, commentId: ID): Observable<TypedList<CompanyComment>> {
    return this.networkService.delete<TypedList<CompanyComment>>(
      ['constrafor', 'companies', companyId, 'profile_notes', 'comments', commentId],
    );
  }

  getCompanyAttachments(id: ID, params?: Params): Observable<TypedList<CompanyAttachment>> {
    return this.networkService.get<TypedList<CompanyAttachment>>(
      ['constrafor', 'companies', id, 'profile_notes', 'attachments', params],
    );
  }

  addCompanyAttachment(id: ID, data: FormData): Observable<TypedList<CompanyAttachment>> {
    return this.networkService.post<TypedList<CompanyAttachment>>(
      ['constrafor', 'companies', id, 'profile_notes', 'attachments'],
      data,
    );
  }

  updateCompanyAttachment(companyId: ID, fileUUID: UUID, data: any): Observable<TypedList<CompanyAttachment>> {
    return this.networkService.patch<TypedList<CompanyAttachment>>(
      ['constrafor', 'companies', companyId, 'profile_notes', 'attachments', fileUUID],
      data,
    );
  }

  deleteCompanyAttachment(companyId: ID, fileUUID: UUID): Observable<TypedList<CompanyAttachment>> {
    return this.networkService.delete<TypedList<CompanyAttachment>>(
      ['constrafor', 'companies', companyId, 'profile_notes', 'attachments', fileUUID],
    );
  }

  getCompanyTrades(id: ID): Observable<CompanyTrade[]> {
    return this.networkService.get<CompanyTrade[]>(['constrafor', 'companies', id, 'profile_notes', 'trades']);
  }

  addCompanyTrades(id: ID, data: TradesToConsider): Observable<CompanyTrade[]> {
    return this.networkService.put<CompanyTrade[]>(['constrafor', 'companies', id, 'profile_notes', 'trades'], data);
  }

  getCompanyScore(id: ID): Observable<CompanyScore> {
    return this.networkService.get<CompanyScore>(['constrafor', 'companies', id, 'profile_notes', 'score']);
  }

  updateCompanyScore(id: ID, data: CompanyScore): Observable<CompanyScore> {
    return this.networkService.patch<CompanyScore>(['constrafor', 'companies', id, 'profile_notes', 'score'], data);
  }

  getFilesListForExport(): Observable<ExportRequest[]> {
    return this.networkService.get<ExportRequest[]>(['file-manager', 'export']);
  }

  requestFilesListForExport(subtype: ExportRequestSubtype | 'all'): Observable<{ created: boolean }> {
    return this.networkService.post<{ created: boolean }>(['file-manager', 'export'], { subtype });
  }

  getEmailValidityInfo(email: string): Observable<EmailValidityInfo> {
    return this.networkService.get<TypedList<EmailValidityInfo>>(['notifier', 'email-validity', { email }]).pipe(
      pluck('results'),
      map(results => results[0]),
    );
  }

  addWorkflowRule(data: WorkflowRulePayload): Observable<WorkflowRule> {
    return this.networkService.post<WorkflowRule>(
      ['constrafor', 'wf-templates'],
      data,
    );
  }

  getProjectsWorkflowRules(params?: Params): Observable<{ id: ID, title: string }[]> {
    return this.networkService.get<{ id: ID, title: string }[]>(
      ['constrafor', 'document_projects', 'wf-templates', params],
    );
  }

  getProjectsWorkflowRule(id: ID): Observable<ContractRule> {
    return this.networkService.get<ContractRule>(
      ['constrafor', 'document_projects', 'wf-templates', id],
    );
  }

  getWorkflowRules(params?: Params): Observable<TypedList<WorkflowTableRow>> {
    return this.networkService.get<TypedList<WorkflowTableRow>>(
      ['constrafor', 'wf-templates', params],
    );
  }

  getWorkflowRule(id: ID): Observable<WorkflowRule> {
    return this.networkService.get<WorkflowRule>(
      ['constrafor', 'wf-templates', id],
    );
  }

  deleteWorkflowRule(id: ID): Observable<WorkflowRule> {
    return this.networkService.delete<WorkflowRule>(
      ['constrafor', 'wf-templates', id],
    );
  }

  getCertificationsSearchFunction(): (term: string) => Observable<DropdownOption[]> {
    const defaultOption = { value: '', viewValue: 'All' };
    return (term, extraParams?) => this.getCertifications({
      name: term,
      page_size: 10,
      page: 1,
      ...extraParams,
    }).pipe(
      map(types => {
        const mappedValues = types
          .map(project => ({ value: project.id, viewValue: project.name }))
          .filter(option => option.viewValue);
        return [defaultOption, ...mappedValues];
      }),
      startWith([defaultOption]),
    );
  }
}

