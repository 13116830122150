import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject, forkJoin } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, map, tap, take, first } from 'rxjs/operators';
import { UserService } from './user.service';
import { CompanyService } from './company.service';
import moment from 'moment';

declare const WebViewer: any;

interface ButtonOptions {
  type: 'actionButton';
  img: string;
  title: string;
  onClick: () => void;
}

const SAVE_ICON = (
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">' +
  '  <path d="M0 0h24v24H0z" fill="none"/>' +
  '  <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>' +
  '</svg>'
);

const CROSS_ICON = (
  '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">' +
  '  <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>' +
  '  <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>' +
  '</svg>'
);

const RED_CROSS_ICON = (
  '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">' +
  '  <path fill="#9f0303" fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>' +
  '  <path fill="#9f0303" fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>' +
  '</svg>'
);

const GREEN_CHECK_ICON = (
  '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">' +
    '<path fill="#3a6a25" d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>' +
  '</svg>'
);

@Injectable({
  providedIn: 'root',
})
export class PdfjsExpressService {
  wvInstance: any;
  currentPdfUrl: string;
  isViewerActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  editResult$: Subject<File> = new Subject<File>();
  viewerElementRef: ElementRef;
  cdrInterval: number;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private companyService: CompanyService,
  ) {
  }

  setViewerElementRef(ref: ElementRef): void {
    this.viewerElementRef = ref;
  }

  loadFileToViewer(url: string): void {
    const username$ = this.getUsername().pipe(
      tap(username => {
        this.wvInstance.annotManager.setCurrentUser(username);
      }),
    );
    const wvInstance$ = this.initPdfjsExpressViewer(this.viewerElementRef).pipe(
      tap(instance => this.wvInstance = instance),
    );
    (this.wvInstance ? username$ : wvInstance$).pipe(
      take(1),
    ).subscribe(
      () => {
        this.currentPdfUrl = url;
        this.wvInstance.UI.loadDocument(url);
        this.isViewerActive$.next(true);
      },
    );
  }

  stopManualChangeDetection(): void {
    this.cdrInterval && clearInterval(this.cdrInterval);
  }

  private createWebViewerInstance(viewerElementRef: ElementRef, userName: string): Observable<any> {
    return from(WebViewer({
      path: '../../wv-resources/lib',
      annotationUser: userName,
      disableFlattenedAnnotations: true,
    }, viewerElementRef.nativeElement));
  }

  private extractAnnotations(body: any = null): Observable<any> {
    return this.http.post<any>('https://api.pdfjs.express/xfdf/extract', body);
  }

  private setAnnotations(body: any = null): Observable<any> {
    return this.http.post<any>('https://api.pdfjs.express/xfdf/set', body);
  }

  private getAnnotatedFile(url: string, key: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', key);
    return this.http.get(url, { responseType: 'arraybuffer', headers });
  }

  private saveFileThroughApi(fileUrl: string, fileName: string, xfdfString: string): Observable<File> {
    const formData: FormData = new FormData();
    formData.append('file', fileUrl);
    formData.append('xfdf', xfdfString);
    return this.setAnnotations(formData).pipe(
      switchMap((res) => {
        return this.getAnnotatedFile(res.url, res.key);
      }),
      map(blobArray => {
        const blob = new Blob([blobArray], { type: 'application/pdf' });
        return new File([blob], fileName, { type: 'application/pdf' });
      }),
    );
  }

  private onDocLoad(): void {
    this.wvInstance.openElements(['loadingModal']);
    this.wvInstance.annotManager.getCurrentUser();
    const formData: FormData = new FormData();
    formData.append('file', this.currentPdfUrl);
    this.extractAnnotations(formData).pipe(
      switchMap((res) => {
        return this.wvInstance.Core.annotationManager.importAnnotations(res.xfdf);
      }),
      tap(() => {
        this.wvInstance.closeElements(['loadingModal']);
        this.wvInstance.openElements(['notesPanel']);
      }),
    ).subscribe();
  }

  private apiSave = (): void => {
    this.wvInstance.openElements(['loadingModal']);
    from(this.wvInstance.Core.annotationManager.exportAnnotations()).pipe(
      switchMap((xfdfString: string) => {
        return this.saveFileThroughApi(
          this.currentPdfUrl, this.wvInstance.Core.documentViewer.getDocument().getFilename(), xfdfString,
        );
      }),
      tap(fileWithAnnotations => {
        this.wvInstance.Core.documentViewer.closeDocument();
        this.wvInstance.closeElements(['loadingModal']);
        this.editResult$.next(fileWithAnnotations);
        this.isViewerActive$.next(false);
      }),
    ).subscribe();
  }

  private closeEditor = (): void => {
    this.wvInstance.openElements(['loadingModal']);
    this.wvInstance.UI.closeDocument();
    this.isViewerActive$.next(false);
    this.editResult$.next(null);
  }

  private addButtons(instance: any): void {
    instance.UI.setHeaderItems(header => {
      const saveButtonConfig: ButtonOptions = {
        type: 'actionButton',
        img: SAVE_ICON,
        title: 'API Save',
        onClick: this.apiSave,
      };
      const closeButtonConfig: ButtonOptions = {
        type: 'actionButton',
        img: CROSS_ICON,
        title: 'Close',
        onClick: this.closeEditor,
      };
      const approveButtonConfig: ButtonOptions = {
        type: 'actionButton',
        img: GREEN_CHECK_ICON,
        title: 'Add approval stamp',
        onClick: this.addApprovedStamp,
      };
      const rejectButtonConfig: ButtonOptions = {
        type: 'actionButton',
        img: RED_CROSS_ICON,
        title: 'Add rejection stamp',
        onClick: this.addRejectedStamp,
      };
      header.push(saveButtonConfig);
      header.push(closeButtonConfig);
      header.getHeader('toolbarGroup-Insert').get('signatureToolGroupButton')
        .insertBefore({ type: 'divider' })
        .insertBefore(rejectButtonConfig)
        .insertBefore(approveButtonConfig);
    });
  }

  private addApprovedStamp = () => this.addCustomStamp({ background: '#e2eddd', color: '#3a6a25', title: 'APPROVED' });

  private addRejectedStamp = () => this.addCustomStamp({ background: '#fbccce', color: '#9f0303', title: 'REJECTED' });

  private addCustomStamp = (config: { background: string, color: string, title: string }): void => {
    const {Annotations} = this.wvInstance;
    const stampAnnot = new Annotations.StampAnnotation();
    stampAnnot.PageNumber = 1;
    stampAnnot.X = 100;
    stampAnnot.Y = 100;
    stampAnnot.Width = 300;
    stampAnnot.Height = 50;
    stampAnnot.Author = this.wvInstance.annotManager.getCurrentUser();

    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 50;
    canvas.style.border = '3px solid #000';
    const context = canvas.getContext('2d');
    context.fillStyle = config.background;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeRect(0, 0, canvas.width, canvas.height);
    context.textBaseline = 'middle';
    context.font = 'italic bold 26px Arial';
    context.fillStyle = config.color;
    context.textAlign = 'center';
    context.fillText(config.title, canvas.width / 2, canvas.height / 3);
    context.font = 'italic bold 14px Arial';
    context.fillStyle = config.color;
    context.textAlign = 'center';
    const now = moment().format('h:mm a, MMM D, YYYY');
    context.fillText(`By ${stampAnnot.Author.split(',')[0]} at ${now}`, canvas.width / 2, canvas.height * 4 / 5, 290);

    stampAnnot.ImageData = canvas.toDataURL();
    this.wvInstance.annotManager.addAnnotation(stampAnnot);
    this.wvInstance.annotManager.redrawAnnotation(stampAnnot);
  }

  private getUsername(): Observable<string> {
    return forkJoin({
      user: this.userService.getCurrentUser$().pipe(first()),
      company: this.companyService.getUserCompany().pipe(first()),
    }).pipe(
      map(({user, company}) => `${user.display_name}, ${company.name}`),
    );
  }

  private initPdfjsExpressViewer(div: ElementRef): Observable<any> {
    return this.getUsername().pipe(
      switchMap(userName => {
        return this.createWebViewerInstance(div, userName).pipe(
          take(1),
          map((instance: any) => {
            instance.annotManager.promoteUserToAdmin();

            const createRubberStampTool = instance.Core.documentViewer.getTool('AnnotationCreateRubberStamp');
            const stampSubtitle = '[By $currentUser at] h:mm a, MMM D, YYYY';
            const customStamps = [
              { title: 'CONFIDENTIAL', subtitle: stampSubtitle, color: new instance.Core.Annotations.Color(161, 7, 6) },
              { title: 'APPROVED', subtitle: stampSubtitle, color: new instance.Core.Annotations.Color(59, 112, 13) },
              { title: 'RECEIVED', subtitle: stampSubtitle, color: new instance.Core.Annotations.Color(20, 38, 104) },
              { title: 'REVIEWED', subtitle: stampSubtitle, color: new instance.Core.Annotations.Color(20, 38, 104) },
            ];
            createRubberStampTool.setCustomStamps(customStamps);

            instance.Core.documentViewer.addEventListener('documentLoaded', () => {
              this.onDocLoad();
            });
            this.addButtons(instance);
            return instance;
          }),
        );
      }),
    );
  }
}

