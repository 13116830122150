import { Store } from '@ngrx/store';
import { User } from 'src/app/models/user';
import { ChatService } from '../chat.service';
import { UserService } from 'src/app/core/services/user.service';
import { selectChat } from '../store/selectors/chat.selectors';
import { ChatInitialState } from '../store/reducers/chat.reducer';
import { PubNubChannel, PubNubMessage, PubNubMessageAuthor } from '../../models/chat.interface';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

const EMPTY_ARRAY_OF_MESSAGES = [];

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  currentUser: User;
  allChannels: PubNubChannel[] = null;
  selectedChat: PubNubChannel = null;
  message = '';
  sending = false;
  private author: PubNubMessageAuthor;
  private chatTitle = null;
  private selectedChannel: PubNubChannel = null;
  private messages: ChatInitialState['messages'] = {};
  @ViewChild('messagesScrollAreaRef') messagesScrollAreaRef: ElementRef;

  constructor(
    private store: Store<{ chat: ChatInitialState }>,
    private chatService: ChatService,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.chatTitle = this.route.snapshot.paramMap.get('chatTitle');
    this.chatService.init();

    this.userService.getCurrentUser$().subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.author = {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          company_id: user.company_id,
        };
      }
    });

    this.store
      .select(selectChat)
      .subscribe(state => {
        this.allChannels = state.channels
          .filter(channel => channel.companies && channel.companies.length >= 2)
          .map(
            channel => ({
              ...channel,
              interlocutor: channel.companies.find(company => company.id !== this.currentUser.company_id),
            }),
          );
        if (this.allChannels.length && this.chatTitle) {
          const channel = this.allChannels.find(ch => ch.title === this.chatTitle);
          if (channel) {
            this.onSelectChat(channel);
          }
          this.chatTitle = null;
        }
        if (this.allChannels.length && state.activeCompanyChat) {
          const channel = this.allChannels.find(ch => ch.interlocutor.id === state.activeCompanyChat);
          if (channel) {
            this.chatService.resetActiveCompanyChat();
            this.onSelectChat(channel);
          }
        }
        this.messages = state.messages;
      });

    this.chatService
      .onNewMessage$()
      .subscribe(({ message, channel }) => {
        this.focusInputAndScrollBottom();
        this.onNewMessage(channel, message);
      });
  }

  ngOnDestroy(): void {
    this.selectedChannel = null;
  }

  get realMessages(): any[] {
    const messages = this.getCurrentChannelMessages();
    return messages
      .filter(m => !m.hasOwnProperty('last_seen'))
      .map(m => ({ ...m, header: this.messageHeader(m) }));
  }

  onSelectChat(channel: PubNubChannel): void {
    this.selectedChat = channel;
    if (channel) {
      this.selectedChannel = channel;
      this.focusInputAndScrollBottom();
      const unseenMessages = this.chatService.getChannelUnseenMessagesCount(channel, this.currentUser);
      if (unseenMessages) {
        const lastMessage = this.chatService.getChannelLastMessage(channel, this.currentUser, true);
        this.chatService.markLastSeenMessage(channel.title, this.author, lastMessage);
      }
    }
  }

  private focusInputAndScrollBottom(): void {
    const interval = setInterval(() => {
      if (this.messagesScrollAreaRef) {
        this.messagesScrollAreaRef.nativeElement.scrollTop = this.messagesScrollAreaRef.nativeElement.scrollHeight;
        clearInterval(interval);
      }
    }, 1);
  }

  private getCurrentChannelMessages(): any[] {
    if (!this.selectedChannel) {
      return EMPTY_ARRAY_OF_MESSAGES;
    }
    const messages = this.messages[this.selectedChannel.title];
    if (messages === undefined) {
      return EMPTY_ARRAY_OF_MESSAGES;
    }
    return messages;
  }

  sendMessage(): void {
    if (!this.message || this.message.length > 10000 || !this.message.trim().length) {
      return;
    }
    this.sending = true;
    this.chatService.sendMessage(this.selectedChannel.title, this.author, this.message).subscribe(() => {
      this.message = '';
      this.sending = false;
    });
  }

  uploadFile(files: any): void {
    for (const file of files) {
      this.chatService.sendFile(this.selectedChannel.title, this.author, file);
    }
  }

  downloadFile(url: string): void {
    window.open(url);
  }

  get recipient(): { full_name: string; company_name: string; avatar: string } {
    return {
      full_name: this.selectedChat.interlocutor.name,
      company_name: this.selectedChat.interlocutor.location,
      avatar: this.selectedChat.interlocutor.logo_url,
    };
  }

  private onNewMessage(channelTitle: string, message: PubNubMessage): void {
    if (
      this.selectedChannel &&
      this.selectedChannel.title === channelTitle &&
      !message.hasOwnProperty('last_seen') &&
      this.currentUser.id !== message.author.id
    ) {
      this.chatService.markLastSeenMessage(channelTitle, this.author, message);
    }
  }

  private messageHeader(message: PubNubMessage): string {
    const timestamp = moment(message.date_published).calendar();
    const companyName = this.selectedChat.companies.find(company => company.id === message.author.company_id).name;
    return `${message.author.first_name} ${message.author.last_name}, ${companyName}. ${timestamp}`;
  }
}
