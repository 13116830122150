import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { first, map, pluck, switchMap, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  MembershipStatusEnum,
  PendingMembership,
  RoleType,
  TwoFactorAuthenticationMethods,
  User,
} from '../../models/user';
import { selectUser } from '../store/selectors/user.selectors';
import { updateUser } from '../store/actions/user.actions';
import { NotificationSettings, ProjectNotificationSettings, ReminderRule } from '../../models/notification-settings';
import { UserDashboardInfo } from '../../models/user-dashboard-info';
import { NetworkService, Params, TypedList } from '../api/network.service';
import { AdminAlert } from '../components/admin-alerts/interfaces/admin-alert';
import { InterestedInRequest } from '../../models/interested-in';
import { ID } from '../../models/id';
import { UserTwoFactorAuthenticationInfo } from '../../models/user-2fa';
import { Permission } from '../../models/permission';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private networkService: NetworkService,
    private store: Store<{ user: User }>,
  ) {
  }

  getCurrentUser$(): Observable<User> {
    return this.store.pipe(
      first(),
      select(selectUser),
      map(user => {
        return user && user.id ? user : null;
      }),
    );
  }

  fetchUser(): Observable<User> {
    return this.networkService.get<User>(['users', 'current_user']).pipe(
      tap(user => {
        if (user?.id) {
          this.store.dispatch(updateUser({
            payload: user,
          }));
        }
      }),
    );
  }

  getUserDashboardInfo(): Observable<UserDashboardInfo> {
    return this.networkService.get<UserDashboardInfo>(['users', 'current_user', 'dashboard']);
  }

  getNotificationSettings(): Observable<NotificationSettings[]> {
    return this.networkService.get<NotificationSettings[]>(['notifier', 'membership_settings']);
  }

  getProjectsNotificationsSettings(): Observable<ProjectNotificationSettings[]> {
    return this.networkService.get<ProjectNotificationSettings[]>(['notifier', 'settings', 'projects']);
  }

  patchProjectNotificationSettings(
    settings: { email: boolean; system: boolean; project: ID }[],
  ): Observable<ProjectNotificationSettings[]> {
    return this.networkService.put<ProjectNotificationSettings[]>(['notifier', 'settings', 'projects'], settings);
  }

  setNotificationSettings(notificationSettings: Partial<NotificationSettings>): Observable<NotificationSettings> {
    return this.networkService.patch<NotificationSettings>(
      ['notifier', 'membership_settings', notificationSettings.id],
      notificationSettings,
    );
  }

  getSubcontractorRemindersSettings(): Observable<ReminderRule[]> {
    return this.networkService.get<ReminderRule[]>(['notifier', 'reminders_rules']);
  }

  patchSubcontractorRemindersSettings(reminderRule: ReminderRule): Observable<ReminderRule> {
    const { id, week_frequency, days_of_week } = reminderRule;
    const payload = { week_frequency, days_of_week };
    return this.networkService.patch<ReminderRule>(['notifier', 'reminders_rules', id], payload);
  }

  patchUser(data: Partial<User>): Observable<User> {
    return this.networkService.patch<User>(['users', 'current_user'], data).pipe(
      tap(updatedUser => {
        this.store.dispatch(updateUser({
          payload: updatedUser,
        }));
      }),
    );
  }

  hasPermissions(permissions: Permission[], exact: boolean = false): boolean {
    const user = this.getCurrentUserFromStore();

    if (!user) {
      return false;
    }

    if (exact) {
      return permissions.every(permission => user.permissions.includes(permission));
    }

    return permissions.some(permission => user.permissions.includes(permission));
  }

  connectTextura(data: { user_name: string, password: string }): Observable<{ login: string }> {
    return this.networkService.post<{ login: string }>(['textura', 'credentials'], data);
  }

  getTextura(): Observable<TypedList<{ login: string }>> {
    return this.networkService.get<TypedList<{ login: string }>>(['textura', 'credentials']);
  }

  private getCurrentUserFromStore(): User {
    let user: User;
    this.getCurrentUser$().subscribe(data => {
      user = data;
    });
    return user;
  }

  isUserCompanyGC(): boolean {
    return this.getCurrentUserFromStore()?.is_company_gc || false;
  }

  getAdminAlerts(): Observable<AdminAlert[]> {
    return this.networkService.get<AdminAlert[]>(['notifier', 'alerts']);
  }

  getQuickbooksIntegrationLink(): Observable<{ auth_url: string }> {
    return this.networkService.post<{ auth_url: string }>(['integrations', 'quickbooks', 'auth']);
  }

  sendInterestRequest(data: Partial<InterestedInRequest>): Observable<null> {
    return this.networkService.post<null>(['constrafor', 'interests'], data);
  }

  updateTwoFaMethod(userId: ID, payload: { mfa_method: TwoFactorAuthenticationMethods }): Observable<UserTwoFactorAuthenticationInfo> {
    return this.networkService.patch<UserTwoFactorAuthenticationInfo>(['users', userId, 'mfa_settings'], payload).pipe(
      tap(updated2FaInfo => {
        this.getCurrentUser$().subscribe(user => {
          this.store.dispatch(updateUser({
            payload: { ...user, mfa_method: updated2FaInfo.mfa_method },
          }));
        });
      }),
    );
  }

  markPageAsVisited(pageName: string): void {
    this.getCurrentUser$().pipe(
      switchMap(user => this.patchUser(
        { visited_pages: [...user.visited_pages, pageName] },
      )),
    ).subscribe();
  }

  getCompanyPendingMemberships(params?: Params): Observable<TypedList<PendingMembership>> {
    return this.networkService.get<TypedList<PendingMembership>>(['users', 'membership_requests', params]);
  }

  getPendingMembershipsCount(): Observable<number> {
    return this.networkService.get<TypedList<PendingMembership>>(['users', 'membership_requests']).pipe(
      pluck('count'),
    );
  }

  acceptMembership(id: ID, role: RoleType): Observable<{ success: boolean }> {
    return this.networkService.patch<{ success: boolean }>(
      ['users', 'membership_requests', id],
      { status: MembershipStatusEnum.ACCEPTED, role },
    );
  }

  declineMembership(id: ID): Observable<{ success: boolean }> {
    return this.networkService.patch<{ success: boolean }>(
      ['users', 'membership_requests', id],
      { status: MembershipStatusEnum.DECLINED },
    );
  }
}
