import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '../../models/user';
import { ChatService } from '../chat.service';
import { PubNubChannel } from '../../models/chat.interface';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewChannelDialogComponent } from '../create-new-channel-dialog/create-new-channel-dialog.component';


@Component({
  selector: 'app-chat-companies',
  templateUrl: './chat-companies.component.html',
  styleUrls: ['./chat-companies.component.scss'],
})
export class ChatCompaniesComponent implements OnInit {
  searchControl = new FormControl('');
  filteredChannels: PubNubChannel[];
  @Input() channels: PubNubChannel[];
  @Input() private user: User;
  @Output() private selectChat = new EventEmitter<PubNubChannel>();

  constructor(
    private chatService: ChatService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe((value: string) => {
      this.filteredChannels = this.channels.filter(channel => channel.interlocutor.name?.toLowerCase().includes(value));
    });
  }

  onSelectChat(channel: PubNubChannel): void {
    this.selectChat.emit(channel);
  }

  getChatUnreadCount(channel: PubNubChannel): null | '99+' | number {
    if (channel) {
      const unreadCount = this.chatService.getChannelUnseenMessagesCount(channel, this.user);
      return unreadCount > 99 ? '99+' : unreadCount;
    }
    return null;
  }

  openCreateChannelDialog(): void {
    this.dialog.open(CreateNewChannelDialogComponent);
  }
}
