import { mapTo, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserService } from '../core/services/user.service';
import { LogRocketService } from '../core/services/log-rocket.service';
import { initHubspot } from '../../lib/hubspot';
import { ChatService } from '../chat/chat.service';
import { PubNubService } from '../core/services/pubnub.service';
import { NotificationService } from '../core/services/notification.service';

export function initializer(
  logRocketService: LogRocketService,
  userService: UserService,
  pubnubService: PubNubService,
  notificationService: NotificationService,
  chatService: ChatService,
): () => Observable<boolean> {
  return () => logRocketService.init().pipe(
    switchMap(() => userService.fetchUser()),
    tap(user => {
      if (user?.id) {
        pubnubService.init();
        chatService.simplifiedInit();
        notificationService.init();
        initHubspot(user);
      }
    }),
    mapTo(true),
  );
}
