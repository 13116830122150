import { Action, createReducer, on } from '@ngrx/store';

import { resetRegistrationManager, updateRegistrationManager } from './registration.actions';
import { SimilarCompany } from '../../../models/company';

export enum CompanyTypeEnum {
  CONTRACTOR = 'contractor',
  GENERAL_CONTRACTOR = 'gencontractor',
  PROJECT_OWNER = 'project_owner',
  BROKER = 'broker',
  LENDER = 'lender',
}

export type CompanyType = CompanyTypeEnum.CONTRACTOR
  | CompanyTypeEnum.GENERAL_CONTRACTOR
  | CompanyTypeEnum.PROJECT_OWNER
  | CompanyTypeEnum.BROKER
  | CompanyTypeEnum.LENDER;

export interface RegistrationManager {
  invitationCode: string;
  invitationEmail: string;
  invitationCompanyName: string;
  isInvitationError: boolean;
  queryParameterEmail: string;
  queryParameterCompanyType: CompanyType;
  passwordStrengthScale: number;
  similarCompanies: SimilarCompany[];
}

export const initialState = { };

const reducer = createReducer(
  initialState,
  on(updateRegistrationManager, (state, { payload }) => ({ ...state, ...payload })),
  on(resetRegistrationManager, () => initialState),
);

export function registrationManagerReducer(state: RegistrationManager, action: Action): Partial<RegistrationManager> {
  return reducer(state, action);
}
