import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PdfjsExpressService } from '../core/services/pdfjs-express.service';

@Component({
  selector: 'app-web-viewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.css'],
})
export class WebviewerComponent implements AfterViewInit{

  @ViewChild('viewer') viewerElementRef: ElementRef;

  constructor(
    private pdfjsExpressService: PdfjsExpressService,
  ) {}

  ngAfterViewInit(): void {
    this.pdfjsExpressService.setViewerElementRef(this.viewerElementRef);
  }

}
