import { Injectable, NgZone } from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { environment } from 'src/environments/environment';
import { PubNubStatus } from '../../models/chat.interface';
import { from, Observable } from 'rxjs';

export interface PubNubHistory {
  startTimeToken: number;
  endTimeToken: number;
  messages: {
    entry: any;
    timetoken: number;
  }[];
}

interface PubNubMessageNotification {
  channel: string;
  message: object;
  meta?: object;
}

@Injectable({
  providedIn: 'root',
})
export class PubNubService {
  constructor(
    private pubnub: PubNubAngular,
    private ngZone: NgZone,
  ) {
  }

  init(): void {
    this.pubnub = new PubNubAngular();
    this.pubnub.init({
      publishKey: environment.pubnubPubKey,
      subscribeKey: environment.pubnubSubKey,
      ssl: true,
    });

    const filterExprStr = `uuid != '${this.pubnub.getUUID()}'`;
    this.pubnub.setFilterExpression(filterExprStr);
  }

  stop(): void {
    if (this.pubnub && this.pubnub.hasOwnProperty('stop')) {
      this.pubnub.stop();
      this.pubnub = null;
    }
  }

  publish(message: PubNubMessageNotification, callback?: (status: PubNubStatus, response: any) => void): void {
    this.pubnub.publish({
      meta: {
        uuid: this.pubnub.getUUID(),
      },
      ...message,
    }, callback);
  }

  subscribe(channels: string[]): void {
    this.ngZone.runOutsideAngular(() => {
      this.pubnub.subscribe({
        channels,
        triggerEvents: true,
        withPresence: true,
      });
    });
  }

  unsubscribeAll(): void {
    if (this.pubnub && this.pubnub.hasOwnProperty('unsubscribeAll')) {
      this.pubnub.unsubscribeAll();
    }
  }

  history(channel: string): Observable<PubNubHistory> {
    return from(this.pubnub.history({ channel })) as Observable<PubNubHistory>;
  }

  getMessage(channelNames: string[], callback: (message: any) => void): any[] {
    return this.pubnub.getMessage(channelNames, callback);
  }

  deleteMessage(channel: string, timetoken: string): void {
    const start = String(Number(timetoken) - 2);
    const end = String(Number(timetoken) + 2);
    this.pubnub.deleteMessages({ channel, start, end }, null);
  }

  deleteMessages(channel: string, start: string, end: string): void {
    this.pubnub.deleteMessages({ channel, start, end }, null);
  }
}
