<div class="page-wrapper">
  <div class="page-content">

    <div class="page-block">
      <img class="page-img" src="/assets/discover-photo.jpg" alt="discover">
      <div class="text-block">
        <h2 class="main-text-block-header">Contractors need to know all their options when it comes to insurance</h2>
        <p class="main-text-block-text">
          Understanding the need for protection, contractors must ensure that their insurance coverage sufficiently protects
          their business should something undesirable occur. The key is to have an insurance program that appropriately matches the risks to
          their business and exposures.
        </p>
      </div>
    </div>

    <h3 class="small-header">EXPLORE OUR PRODUCTS</h3>

    <div class="page-block-discover-top">
      <div class="feature-wrapper first-feature-wrapper">
        <div class="feature-header">General Liability</div>
        <p class="feature-text">
          This liability coverage protects your business if it’s responsible for events like property damage,
          bodily injury or defective work
        </p>
      </div>

      <div class="feature-wrapper">
        <div class="feature-header">Commercial Auto</div>
        <p class="feature-text">
          Protects vehicles used in your business including those that transport your tools and equipment
        </p>
      </div>
    </div>

    <div class="center-button">
      <a mat-flat-button class="feature-button" color="primary" target="_blank" rel="noopener noreferrer"
         href="https://meetings.hubspot.com/tony-guarascio">
        Book a meeting with a specialist
      </a>
    </div>

    <div class="page-block-discover-bottom">
      <div class="feature-wrapper first-feature-wrapper">
        <div class="feature-header">Umbrella / Excess Liability</div>
        <p class="feature-text">
          Umbrella and excess insurance policies are additional layers of coverage above primary insurance policies
        </p>
      </div>

      <div class="feature-wrapper">
        <div class="feature-header">Workers Compensation</div>
        <p class="feature-text">
          Covers employee medical costs and a portion of lost wages if they become ill or injured while at work
        </p>
      </div>
    </div>

    <h3 class="small-header">OUR INSURANCE PARTNER</h3>

    <div class="page-block">
      <div class="feature-wrapper feature-wrapper-large">
        <div class="large-feature-block">
          <img class="feature-img" src="/assets/avalon-pacific-insurance-logo.jpg" alt="avalon-pacific-insurance-logo">
          <div class="icon-buttons-block">
            <a mat-icon-button target="_blank" rel="noopener noreferrer" href="https://meetings.hubspot.com/tony-guarascio">
              <mat-icon>chat_bubble</mat-icon>
            </a>
            <a mat-icon-button href="tel:+1-650-313-6165">
              <mat-icon>local_phone</mat-icon>
            </a>
          </div>

        </div>
        <div class="large-feature-block">
          <div class="feature-header">Avalon Pacific</div>
          <p class="feature-text">
            Avalon Pacific Insurance is licensed to write business in all U.S States and the District of Columbia. Avalon provides
            general insurance information from respected independent sources, estimated insurance quotes from a wide range of insurance
            companies and, when customers request insurance coverage we attempt to fulfill those requests through applications to
            insurance companies. Insurance companies issue and back insurance policies. If you submit an application for insurance
            through this site Avalon’s designated representatives will act as the agent of the insurance company offering the coverage
            and will assist you to complete the transaction.
          </p>
        </div>
      </div>
    </div>

    <div class="bottom-block">
      <div class="question-header">Don't have your loss history?</div>
      <a mat-flat-button class="feature-button" color="primary" target="_blank" rel="noopener noreferrer"
         href="https://meetings.hubspot.com/tony-guarascio">
        Talk to our experts
      </a>
    </div>

  </div>
</div>
