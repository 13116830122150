<app-cdialog [ngClass]="dialogSettings?.dialogClassName">

  <span cdialog-header class="dialog-custom-header">{{dialogSettings.title}}</span>

  <p
    cdialog-body
    class="dialog-content"
    [ngClass]="dialogSettings?.bodyClassName"
  >
    {{dialogSettings.content}}
  </p>

  <div
    cdialog-footer
    [ngClass]="dialogSettings?.footerClassName"
  >
    <button
      *ngIf="dialogSettings?.showCancelButton"
      mat-stroked-button
      color="primary"
      (click)="onCancel()"
    >
      {{dialogSettings?.cancelButtonText || 'Cancel'}}
    </button>
    <button
      *ngIf="!dialogSettings?.hideConfirmButton"
      mat-flat-button
      color="primary"
      (click)="onConfirm()"
    >
      {{dialogSettings?.confirmButtonText || 'Confirm'}}
    </button>
  </div>
</app-cdialog>
