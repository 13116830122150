<div [hidden]="isPdfjsExpressViewerActive$ | async">
  <app-progress-bar [loading]="loading"></app-progress-bar>

  <app-header></app-header>

  <app-admin-alerts-container class="alerts-container"></app-admin-alerts-container>

  <router-outlet></router-outlet>
</div>
<app-web-viewer [hidden]="!(isPdfjsExpressViewerActive$ | async)"></app-web-viewer>
