<div class="nav-padding"></div>
<mat-toolbar class="header-landing">
  <button
    mat-icon-button
    class="mobile-burger mobile-only"
    [matMenuTriggerFor]="hamburgerMenu"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <img
    src="../../../../../../assets/Logo-02_crop.png"
    class="logo-image"
    alt="logo"
  />

  <div class="landing-desktop-buttons desktop-only">
    <button
      class="landing-button"
      mat-stroked-button
      color="primary"
      routerLink="/auth/register"
    >
      SIGN UP FOR FREE
    </button>
    <button
      class="landing-button"
      mat-stroked-button
      color="primary"
      routerLink="/auth/login"
    >
      LOG IN
    </button>
  </div>

  <div class="landing-desktop-buttons mobile-only">
    <button
      class="landing-button"
      mat-stroked-button
      color="primary"
      [disabled]="false"
      routerLink="/auth/register"
    >
      SIGN UP
    </button>
  </div>
</mat-toolbar>

<mat-menu #hamburgerMenu="matMenu">
  <button mat-menu-item routerLink="/auth/register">SIGN UP FOR FREE</button>
  <button mat-menu-item routerLink="/auth/login">LOG IN</button>
</mat-menu>
