import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CDialogComponent } from '../../core/cdialog/cdialog.component';
import { ContractorService } from '../../core/services/contractor.service';
import { Company } from '../../models/company';
import { ChatService } from '../chat.service';

@Component({
  selector: 'app-create-new-channel-dialog',
  templateUrl: './create-new-channel-dialog.component.html',
  styleUrls: ['./create-new-channel-dialog.component.scss'],
})
export class CreateNewChannelDialogComponent extends CDialogComponent implements OnInit {
  companySearchControl = new FormControl('');
  companies$: Observable<Company[]>;
  selectedCompany: Company = null;

  constructor(
    public matDialogRef: MatDialogRef<CreateNewChannelDialogComponent>,
    private contractorService: ContractorService,
    private chatService: ChatService,
  ) {
    super(matDialogRef);
  }

  ngOnInit(): void {
    this.companies$ = this.getCompaniesList();
  }

  private getCompaniesList(): Observable<Company[]> {
    return this.companySearchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => {
        if (typeof value !== 'string') {
          this.selectCompany(value as Company);
          return of([]);
        }

        const params = {
          name: value,
          page: 1,
          page_size: 15,
        };
        return this.contractorService.getCompanies(params);
      }),
    );
  }

  selectCompany(company: Company): void {
    this.companySearchControl.patchValue(company.name, { emitEvent: false });
    this.selectedCompany = company;
  }

  clearSelectedCompany(): void {
    this.companySearchControl.patchValue('', { emitEvent: false });
    this.selectedCompany = null;
  }

  startChat(): void {
    this.chatService.startChatByCompanyId(this.selectedCompany.id).subscribe(() => {
        this.dialogRef.close();
      },
    );
  }
}
