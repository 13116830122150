import { createAction, props } from '@ngrx/store';
import { AdminAlert } from '../interfaces/admin-alert';


export const setAdminAlertsItems = createAction(
  '[Admin Alerts] Set Items',
  props<{ payload: AdminAlert[] }>(),
);

export const fetchAdminAlerts = createAction(
  '[Admin Alerts] Fetch',
);

export const hideAdminAlert = createAction(
  '[Admin Alerts] Hide',
  props<{ payload: string }>(),
);
