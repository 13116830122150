<div class="nav-padding"></div>
<mat-toolbar class="header-landing">
  <button
    mat-icon-button
    class="mobile-burger mobile-only"
    [matMenuTriggerFor]="hamburgerMenu"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <div class="logo-wrapper">
    <a [routerLink]="homePage">
      <img
        src="/assets/Logo-02_crop.png"
        class="logo-image"
        alt="logo"
      />
    </a>
  </div>

  <button
    *ngIf="desktopMenu.scrollWidth - desktopMenu.clientWidth > 25"
    mat-icon-button
    class="btn-scroll desktop-only"
    (mousedown)="startScrollLeft()"
    (mouseup)="stopScroll()"
    (mouseleave)="stopScroll()"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <div #desktopMenu class="text-menu-items desktop-only">
    <ng-container *ngTemplateOutlet="desktopMenuItems"></ng-container>
  </div>

  <button
    *ngIf="desktopMenu.scrollWidth - desktopMenu.clientWidth > 25"
    mat-icon-button
    class="btn-scroll desktop-only"
    (mousedown)="startScrollRight()"
    (mouseup)="stopScroll()"
    (mouseleave)="stopScroll()"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>


  <app-header-account-panel
    [user]="user"
  ></app-header-account-panel>
</mat-toolbar>


<mat-menu #hamburgerMenu="matMenu">
  <a *ngIf="companyType === 'contractor'" mat-menu-item routerLink="/dashboard" routerLinkActive="active">
    <button mat-button color="primary">DASHBOARD</button>
  </a>
  <a *appPermission="'constrafor.can_view_epp_tab'" mat-menu-item routerLink="/early-pay-requests" routerLinkActive="active">
    <button mat-button color="primary">EARLY PAY</button>
  </a>
  <a *appPermission="'constrafor.can_view_project_tab'" mat-menu-item routerLink="/projects" routerLinkActive="active">
    <button mat-button color="primary">PROJECTS</button>
  </a>
  <a *appPermission="'constrafor.can_view_contract_tab'" mat-menu-item routerLink="/contracts" routerLinkActive="active">
    <button mat-button color="primary">CONTRACTS</button>
  </a>
  <a *appPermission="'constrafor.can_view_coi_tab'" mat-menu-item routerLink="/certificates-of-insurance" routerLinkActive="active">
    <button mat-button color="primary">
      <ng-container *appPermission="'constrafor.can_view_insurance_tab' else noPermissionTemplate">
        COI
      </ng-container>
      <ng-template #noPermissionTemplate>
        CERTIFICATES OF INSURANCE
      </ng-template>
    </button>
  </a>
  <a *appPermission="'constrafor.can_view_insurance_tab'" mat-menu-item routerLink="/subcontractor-insurance" routerLinkActive="active">
    <button mat-button color="primary">INSURANCE</button>
  </a>
  <a *appPermission="'constrafor.can_view_invoice_tab'" mat-menu-item routerLink="/invoicing" routerLinkActive="active">
    <button mat-button color="primary">INVOICING</button>
  </a>
  <a *appPermission="'constrafor.can_view_diversity_tab'" mat-menu-item routerLink="/diversity" routerLinkActive="active">
    <button mat-button color="primary">DIVERSITY</button>
  </a>
  <a *ngIf="this.companyType !== 'lender'" mat-menu-item routerLink="/contractors" routerLinkActive="active">
    <button mat-button color="primary">CONTRACTORS</button>
  </a>
  <a mat-menu-item *ngIf="companyType" routerLink="/messages" routerLinkActive="active">
    <button mat-button color="primary">MESSAGES</button>
  </a>
  <a mat-menu-item routerLink="/company-profile" routerLinkActive="active">
    <button mat-button color="primary">PROFILE</button>
  </a>
  <a mat-menu-item routerLink="/user" routerLinkActive="active">
    <button mat-button color="primary">SETTINGS</button>
  </a>
  <a mat-menu-item (click)="logout()">
    <button mat-button color="primary">LOG OUT</button>
  </a>
</mat-menu>

<ng-template #desktopMenuItems>
  <a *ngIf="companyType === 'contractor'" routerLink="/dashboard" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">DASHBOARD</button>
  </a>
  <a *appPermission="'constrafor.can_view_epp_tab'" routerLink="/early-pay-requests" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">EARLY PAY</button>
  </a>
  <a *appPermission="'constrafor.can_view_project_tab'" routerLink="/projects" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">PROJECTS</button>
  </a>
  <a *appPermission="'constrafor.can_view_contract_tab'" routerLink="/contracts" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">CONTRACTS</button>
  </a>
  <a *appPermission="'constrafor.can_view_coi_tab'" routerLink="/certificates-of-insurance" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">
      <ng-container *appPermission="'constrafor.can_view_insurance_tab' else noPermissionTemplate">
        COI
      </ng-container>
      <ng-template #noPermissionTemplate>
        CERTIFICATES OF INSURANCE
      </ng-template>
    </button>
  </a>
  <a *appPermission="'constrafor.can_view_insurance_tab'" routerLink="/subcontractor-insurance" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">INSURANCE</button>
  </a>
  <a *appPermission="'constrafor.can_view_invoice_tab'" routerLink="/invoicing" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">INVOICING</button>
  </a>
  <a *appPermission="'constrafor.can_view_diversity_tab'" routerLink="/diversity" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">DIVERSITY</button>
  </a>
  <a *ngIf="companyType !== 'lender'" routerLink="/contractors" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">CONTRACTORS</button>
  </a>
  <a *appPermission="'constrafor.can_view_lending_tab'" routerLink="/lender-portal" routerLinkActive="active">
    <button class="nav-link" mat-button color="primary">LENDING</button>
  </a>
</ng-template>
