import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-cdialog',
  templateUrl: './cdialog.component.html',
  styleUrls: ['./cdialog.component.scss'],
})
export class CDialogComponent {
  @ViewChild(CDialogComponent) dialog: CDialogComponent;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<CDialogComponent>,
  ) {
  }

  closeDialog(resultData?: any): void {
    this.dialogRef.close(resultData);
  }
}
