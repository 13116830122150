<div class="chat-projects">
  <div class="chat-projects__search">
    <button class="chat-projects__search-btn">
      <mat-icon>search</mat-icon>
    </button>
    <input
      [formControl]="searchControl"
      class="chat-projects__search-input"
      type="text"
      placeholder="Search Chats…"
    >
    <button
      mat-flat-button
      color="primary"
      (click)="openCreateChannelDialog()"
    >
      New
    </button>
  </div>
  <div class="chat-projects__list">
    <div class="chat-projects__item" *ngFor="let channel of (filteredChannels || channels)">
      <div (click)="onSelectChat(channel)" class="chat-projects__request-selected">
        <div class="chat-projects__icon-wrap">
          <div class="chat-projects__icon"></div>
        </div>
        <div *ngIf="getChatUnreadCount(channel)" class="chat-projects__request-messages">
          {{getChatUnreadCount(channel)}}
        </div>
        {{ channel.interlocutor.name }}
      </div>
    </div>
  </div>
</div>
