import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

type RequestUrl = string | any[];

export interface Params {
  [key: string]: number | string | boolean | string[];
}

export interface TypedList<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  constructor(private http: HttpClient) {
  }

  private cleanParams(obj: { [key: string]: any }): void {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
  }

  private buildUrl(url: RequestUrl): string {
    let endSlash = true;
    if (url instanceof Array) {
      url = url.map(value => {
        if (value instanceof Object) {
          endSlash = false;
          this.cleanParams(value);
          const params: string[] = Object.keys(value).map((key: string) => {
            if (value[key] instanceof Array) {
              return value[key].map(nestedValue => `${key}=${encodeURIComponent(nestedValue)}`).join('&');
            }
            const v: any = value[key];
            return `${key}=${encodeURIComponent(v)}`;
          });
          const paramsString: string = params.join('&');
          return `?${paramsString}`;
        }
        return value;
      });
      url = url.join('/');
      if (url.slice(-1) === '/') {
        endSlash = false;
      }
    }
    return `${(environment.API_URL)}/${url}` + (endSlash ? '/' : '');
  }

  post<T>(url: RequestUrl, body: any = null): Observable<T> {
    const resultUrl: string = this.buildUrl(url);
    return this.http.post<T>(resultUrl, body, { withCredentials: true });
  }

  patch<T>(url: RequestUrl, body: any = null): Observable<T> {
    const resultUrl: string = this.buildUrl(url);
    return this.http.patch<T>(resultUrl, body, { withCredentials: true });
  }

  put<T>(url: RequestUrl, body: any = null): Observable<T> {
    const resultUrl: string = this.buildUrl(url);
    return this.http.put<T>(resultUrl, body, { withCredentials: true });
  }

  get<T>(url: RequestUrl): Observable<T> {
    const resultUrl: string = this.buildUrl(url);
    return this.http.get<T>(resultUrl, { withCredentials: true });
  }

  delete<T>(url: RequestUrl): Observable<T> {
    const resultUrl: string = this.buildUrl(url);
    return this.http.delete<T>(resultUrl, { withCredentials: true });
  }
}
