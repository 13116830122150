import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../../../../models/user';

import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { UserService } from '../../../services/user.service';


export const PAGES_WITHOUT_HEADER = [
  '/auth',
  '/auth/login',
  '/auth/register',
  '/auth/verify',
  '/auth/lender-registration',
  '/contracts/view-contract',
  '/contracts/view-template',
  '/invoicing/invoice-management',
  '/contractors/',
  '/projects/',
  '/certificates-of-insurance/certificates-page/certificates/',
  '/lender-portal/invoice/',
  '/subcontractor-insurance/coi-manager',
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: User;
  showHeader$: Observable<boolean>;

  private readonly destroyed$: Subject<void> = new Subject<void>();

  constructor(
    public router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.showHeader$ = this.getShowHeaderObservable();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getShowHeaderObservable(): Observable<boolean> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => !PAGES_WITHOUT_HEADER.some(page => this.router.url.startsWith(page))),
      tap(() => this.updateUser()),
      takeUntil(this.destroyed$),
    );
  }

  private updateUser(): void {
    this.userService.getCurrentUser$().subscribe(user => {
      this.user = user;
    });
  }
}
