import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat/chat.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatCompaniesComponent } from './chat-companies/chat-companies.component';
import { CreateNewChannelDialogComponent } from './create-new-channel-dialog/create-new-channel-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { SharedInitialModule } from '../ui/shared-modules/shared-initial.module';


@NgModule({
  declarations: [
    ChatComponent,
    ChatCompaniesComponent,
    CreateNewChannelDialogComponent,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedInitialModule,
  ],
})
export class ChatModule {
}
