<div class="dialog-container">
  <div mat-dialog-title class="dialog-header-container">
    <div class="dialog-header-text-wrapper">
      <ng-content select="[cdialog-header]"></ng-content>
    </div>
    <div class="dialog-header-control-buttons">
      <div class="certificate-controls-button">
        <ng-content select="[cdialog-header-controls]"></ng-content>
      </div>
      <button mat-flat-button tabindex="-1" class="dialog-header-close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content class="dialog-body-container">
    <ng-content select="[cdialog-body]"></ng-content>
  </div>

  <div mat-dialog-actions class="dialog-footer-container">
    <div class="dialog-footer-content">
      <ng-content select="[cdialog-footer]"></ng-content>
    </div>
    <mat-progress-bar
      class="dialog-footer-loader"
      color="primary"
      mode="indeterminate"
      [ngStyle]="{'visibility': loading ? 'visible' : 'hidden'}"
    >
    </mat-progress-bar>
  </div>

  <div *ngIf="loading" class="dialog-backdrop"></div>
</div>
