<div class="container">
  <div class="chat-main" *ngIf="currentUser">
    <app-chat-companies
      [user]="currentUser"
      [channels]="allChannels"
      (selectChat)="onSelectChat($event)"
    ></app-chat-companies>
    <div *ngIf="!selectedChat" class="chat-dialog">
      <h1 class="empty-chat">Please select a chat to start messaging</h1>
    </div>
    <div *ngIf="selectedChat" class="chat-dialog">
      <div class="chat-dialog__top">
        <div class="chat-dialog__top-left">
          <div class="chat-dialog__person">
            <div class="chat-dialog__person-avatar">
              <img [attr.src]="recipient.avatar || '/assets/ava.png'" alt="avatar" class="chat-dialog__person-avatar-img">
            </div>
            <div class="chat-dialog__person-info">
              <div class="chat-dialog__person-name">{{ recipient.full_name }}</div>
              <div class="chat-dialog__person-company">{{ recipient.company_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-dialog__window" #messagesScrollAreaRef>
        <div
          *ngFor="let message of realMessages"
          class="chat-dialog__message"
          [ngClass]="{
            'chat-dialog__message--right': message.author.id === currentUser.id
          }"
        >
          <div class="chat-dialog__message-container">
            <div class="chat-dialog__message-header">{{ message.header }}</div>
            <div *ngIf="message.text" class="chat-dialog__message-text">{{ message.text }}</div>
            <div
              *ngIf="message.file"
              [ngClass]="{ 'file-message': message.file.link }"
              class="message-file-container chat-dialog__message-text"
              (click)="message.file.link && downloadFile(message.file.link)"
            >
              <div class="file-loader-container">
                <mat-spinner
                  class="white-spinner"
                  [diameter]="35"
                  [strokeWidth]="3"
                  *ngIf="message.upload"
                ></mat-spinner>
                <mat-icon class="file-loader-icon" [ngClass]="{ 'file-loader-icon-uploaded': !message.upload }">insert_drive_file</mat-icon>
              </div>
              <div class="message-file-info-container">
                <b>{{ message.file.name }}</b>
                <span>{{ message.file.size | fileSize }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-dialog__tools-wrap">
        <div class="chat-dialog__tools">
          <div class="chat-dialog__tools-left">
            <button (click)="fileInput.click()" class="chat-dialog__tools-btn">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input hidden multiple type="file" #fileInput (change)="uploadFile($event.target.files)">
          </div>
          <input [(ngModel)]="message" type="text" [attr.readonly]="sending ? '' : null" (keydown.enter)="sendMessage()"
                 class="chat-dialog__text-input" placeholder="Type your message here…">
          <div class="chat-dialog__tools-right">
            <button [attr.disabled]="sending || message.length > 10000? '' : null" class="chat-dialog__tools-btn" (click)="sendMessage()">
              <mat-icon *ngIf="!sending">send</mat-icon>
              <mat-spinner *ngIf="sending" [diameter]="24" [strokeWidth]="3"></mat-spinner>
            </button>
          </div>
        </div>
        <div *ngIf="message.length > 9900 && message.length < 10001" class="chat-dialog-text-error">
          You have {{10000 - message.length}} characters left
        </div>
        <div *ngIf="message.length > 10000" class="chat-dialog-text-error">
          Your message is too long. Please shorten it before sending.
        </div>
      </div>
    </div>
  </div>
</div>
