import { User } from 'src/app/models/user';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import LogRocket from 'logrocket';
import { login, logout, updateUser } from '../core/store/actions/user.actions';
import { ChatService } from '../chat/chat.service';
import { PubNubService } from '../core/services/pubnub.service';
import { NotificationService } from '../core/services/notification.service';
import { broadcastLocalStorageMessage, getCookieByKey, getUserHomePage } from '../core/utils';
import { identifyHubspotUser } from '../../lib/hubspot';
import { environment } from '../../environments/environment';
import { NetworkService, Params } from '../core/api/network.service';
import { HeapService } from '../core/services/heap.service';
import { ID } from '../models/id';
import { CompanyShort, SimilarCompany } from '../models/company';
import { MembershipRequestPayload } from '../models/registration';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private store: Store<{ user: User }>,
    private networkService: NetworkService,
    private router: Router,
    private chatService: ChatService,
    private pubnubService: PubNubService,
    private notificationService: NotificationService,
    private heapService: HeapService,
  ) {
    this.listenAuthEvents();
  }

  identifyUser(user: User): void {
    if (user?.id) {
      const adminEmail = getCookieByKey('admin_email');
      const adminUserId = getCookieByKey('admin_user_id');

      const logRocketParams = adminEmail && adminUserId
        ? {
          name: user.display_name,
          email: user.email,
          is_login_as_session: true,
          admin_email: adminEmail,
          admin_user_id: adminUserId,
        }
        : {
          name: user.display_name,
          email: user.email,
          is_login_as_session: false,
        };

      LogRocket.identify(String(user.id), logRocketParams);

      this.heapService.identify(user, adminEmail, adminUserId);

      identifyHubspotUser(user);
    }
  }

  login(credentials: { email: string, password: string, login_as_user_email?: string }): Observable<User | void> {
    return this.networkService.post<User>(['users', 'login'], credentials).pipe(
      tap(user => {
        if (user) {
          this.storeAndIdentifyUser(user);
        }
      }),
    );
  }

  storeAndIdentifyUser(user: User): void {
    this.store.dispatch(login({
      payload: user,
    }));
    this.pubnubService.init();
    this.chatService.init();
    this.notificationService.init();

    if (environment.production || environment.staging) {
      this.identifyUser(user);
    }

    broadcastLocalStorageMessage('login');
  }

  logout(): Observable<{ success: boolean }> {
    return this.networkService.get<{ success: boolean }>(['users', 'logout']).pipe(
      tap(data => {
        if (data?.success) {
          this.cleanUpSession();

          broadcastLocalStorageMessage('logout');
        }
      }),
    );
  }

  private cleanUpSession(shouldNavigateToAuth: boolean = true): void {
    this.store.dispatch(logout());
    if (shouldNavigateToAuth) {
      this.router.navigate(['auth', 'login']);
    }
    this.notificationService.stop();
    this.chatService.stop();
    this.pubnubService.stop();
  }

  private listenAuthEvents(): void {
    window.addEventListener('storage', (event) => {
      if (event.newValue && event.key === 'logout') {
        this.cleanUpSession();
      }

      if (event.newValue && event.key === 'login') {
        window.location.reload();
      }
    });
  }

  register(data: object): Observable<{ success: boolean }> {
    return this.networkService.post<{ success: boolean }>(['users', 'register'], data);
  }

  resendEmail(email: string): Observable<any> {
    return this.networkService.post<any>(['users', 'resend_verification'], { email });
  }

  verify(code: string, email: string): Observable<User> {
    return this.networkService.post<User>(['users', 'verify'], { code, user: email }).pipe(
      tap(user => {
        if (user) {
          this.store.dispatch(updateUser({
            payload: user,
          }));
        }
      }),
    );
  }

  redirectToHomePage(user: User): void {
    const redirectPath = getUserHomePage(user.company_type);
    if (redirectPath) {
      this.router.navigate([redirectPath]).then((isPageChanged) => {
        if (!isPageChanged) {
          location.reload();
        }
      });
    }
  }

  restorePassword(email: string): Observable<{ success: boolean }> {
    return this.networkService.post<{ success: boolean }>(['users', 'forgot'], { email });
  }

  resetPassword(data: { code: string, password: string, confirm_password: string, pwd_score: number }): Observable<User> {
    return this.networkService.post<User>(['users', 'reset'], data).pipe(
      tap(user => {
        if (user) {
          this.store.dispatch(updateUser({
            payload: user,
          }));
        }
      }),
    );
  }

  acceptInvitation(data: object): Observable<User> {
    return this.networkService.post<User>(['users', 'accept_invitation'], data).pipe(
      tap(user => {
        if (user) {
          this.store.dispatch(updateUser({
            payload: user,
          }));
        }
      }),
    );
  }

  getEmailByInvitationCode(invitationCode: string): Observable<{ company_name: string, email: string }> {
    return this.networkService.get<{ company_name: string, email: string }>(
      ['users', 'accept_invitation', { code: invitationCode }],
    );
  }

  getUserCompanies(): Observable<CompanyShort[]> {
    return this.networkService.get<CompanyShort[]>(['users', 'companies']);
  }

  selectCompany(companyId: ID): Observable<User> {
    return this.networkService.post<User>(['users', 'companies'], { company_id: companyId }).pipe(
      tap(user => {
        if (user) {
          this.cleanUpSession(false);
          this.storeAndIdentifyUser(user);
          this.redirectToHomePage(user);
        }
      }),
    );
  }

  changePassword(password: string): Observable<void> {
    return this.networkService.post<void>(['users', 'change_password'], password);
  }

  validateEmail(email: string): Observable<{ message: string }> {
    return this.networkService.post<{ message: string }>(['users', 'validate'], { email });
  }

  emailLoginVerification(payload: Partial<{ code: string, backup_code: string }>): Observable<User> {
    return this.networkService.post<User>(['users', 'mfa', 'verify'], payload);
  }

  resendEmailVerificationCode(): Observable<void> {
    return this.networkService.post<void>(['users', 'mfa', 'resend']);
  }

  getSimilarCompanies(params: Params): Observable<SimilarCompany[]> {
    return this.networkService.get<SimilarCompany[]>(['constrafor', 'companies', 'redundant', { ...params, only_sub: true }]);
  }

  requestSimilarCompanyMembership(payload: MembershipRequestPayload): Observable<{ success: boolean }> {
    return this.networkService.post<{ success: boolean }>(['users', 'membership_requests'], payload);
  }
}
