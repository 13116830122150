import { createAction, props } from '@ngrx/store';

import { Project } from '../../models/project';


export const projectDetailsUpdated = createAction(
  '[ProjectsItem] Update',
  props<{ payload: Project }>(),
);

export const projectDetailsResetState = createAction(
  '[ProjectsItem] Reset',
);
