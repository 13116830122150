<ng-container *ngIf="showHeader$ | async">
  <ng-container
    *ngTemplateOutlet="['not-found', 'lending', 'insurance'].includes(router.url.split('/')[1])
      && user === null
      && !router.url.startsWith('/contacts') ? landingHeader : userHeader"
  >
  </ng-container>
</ng-container>

<ng-template #userHeader>
  <app-user-header
    [user]="user"
  ></app-user-header>
</ng-template>

<ng-template #landingHeader>
  <app-landing-header></app-landing-header>
</ng-template>
