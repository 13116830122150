import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>(
  'An abstraction over global window object',
  {
    providedIn: 'root',
    factory: () => {
      if (!window) {
        throw new Error('Window is not available');
      }
      return window;
    },
  },
);
