import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../../models/user';
import { login, logout, updateUser } from '../actions/user.actions';


export const initialState: User = null;

const reducer = createReducer(
  initialState,
  on(login, (state: User, { payload }) => payload),
  on(updateUser, (state: User, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(logout, () => null),
);

export function userReducer(state: User | undefined, action: Action): User {
  return reducer(state, action);
}
