import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { addHubspotLoadingScript } from './lib/hubspot';
import { addGoogleTagManagerLoadingScript } from './lib/google-tag-manager';

if (environment.staging || environment.production) {
  addGoogleTagManagerLoadingScript();
  addHubspotLoadingScript();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
