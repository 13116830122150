import { createAction, props } from '@ngrx/store';
import { PubNubChannel, PubNubMessage } from '../../../models/chat.interface';

export const updateChannels = createAction(
  '[Chat] Update Channels',
  props<{ payload: { channels: PubNubChannel[] } }>(),
);

export const updateMessages = createAction(
  '[Chat] Update Channel Messages',
  props<{ payload: { messages: PubNubMessage[]; channel: string } }>(),
);

export const addChannel = createAction('[Chat] Add Channel', props<{ payload: { channel: PubNubChannel } }>());

export const updateChannel = createAction('[Chat] Update Channel', props<{ payload: { channel: PubNubChannel } }>());

export const addMessageToChannel = createAction(
  '[Chat] Add Message To Channel',
  props<{ payload: { channelName: string; message: PubNubMessage } }>(),
);

export const updateMessageInChannel = createAction(
  '[Chat] Update Message',
  props<{ payload: { channelName: string; message: PubNubMessage } }>(),
);

export const setActiveCompanyChat = createAction(
  '[Chat] Set active company Chat',
  props<{ payload: { companyId } }>(),
);

export const setTotalUnreadMessages = createAction(
  '[Chat] Set total unread messages',
  props<{ payload: { messageCount: number } }>(),
);

export const clearMessages = createAction('[Chat] Clear messages');

export const clearChannels = createAction('[Chat] Clear channels');
