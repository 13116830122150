import { MockResponse } from './mock.models';


export const mockResponses: MockResponse[] = [
  // {
  //   state: RequestStateEnum.SUCCESS,
  //   method: 'GET',
  //   path: '/constrafor/mock_api/',
  //   response: {
  //     status: 200,
  //     body: { success: true },
  //   },
  // },
  // {
  //   state: RequestStateEnum.ERROR,
  //   method: 'GET',
  //   path: '/constrafor/mock_error_api/',
  //   error: { error: 'The request has failed', status: 404 },
  // },
];
