<app-cdialog>
  <span cdialog-header>Start a new chat</span>
  <div cdialog-body>
    <form class="new-channel-form">
      <mat-form-field class="form-field-full-width" appearance="outline">
        <mat-label>Company name</mat-label>
        <input
          type="text"
          aria-label="Company search"
          matInput
          [formControl]="companySearchControl"
          [matAutocomplete]="auto"
          (focus)="clearSelectedCompany()"
        >
        <mat-autocomplete #auto="matAutocomplete">
          <ng-container *ngIf="companies$ | async as companies">
            <ng-container *ngIf="companies.length > 0; else emptyList">
              <mat-option
              *ngFor="let company of companies"
              [value]="company"
              (click)="selectCompany(company)"
            >
              {{company.name}}
            </mat-option>
            </ng-container>
            <ng-template #emptyList>
              <mat-option disabled>
                <span class="item-preview">No Results Found</span>
              </mat-option>
            </ng-template>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div>
  <div cdialog-footer>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!selectedCompany"
      (click)="startChat()"
    >
      START CHAT
    </button>
    <button
      mat-stroked-button
      color="primary"
      (click)="closeDialog()"
    >
      CANCEL
    </button>
  </div>
</app-cdialog>
