import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let currentUser: User;

    this.userService.getCurrentUser$().subscribe(user => currentUser = user);

    if (currentUser.company_type === 'lender') {
      this.router.navigate(['lender-portal']);
      return false;
    }
    return true;
  }
}
