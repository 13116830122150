import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';

import { HeaderComponent } from './header/header.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedInitialModule } from '../../../ui/shared-modules/shared-initial.module';
import { UserHeaderComponent } from './header/user-header/user-header.component';
import { LandingHeaderComponent } from './header/landing-header/landing-header.component';
import { HeaderAccountPanelComponent } from './header/user-header/header-account-panel/header-account-panel.component';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatListModule,
    MatProgressSpinnerModule,
    SharedInitialModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CompanyInfoComponent,
    HeaderComponent,
    NotificationsComponent,
    UserHeaderComponent,
    LandingHeaderComponent,
    HeaderAccountPanelComponent,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class NavigationModule {
}
