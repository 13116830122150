import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteHistoryService {
  previousUrl = new BehaviorSubject<string>(null);
  currentUrl = new BehaviorSubject<string>(null);

  handleUrlChange(currentUrl: string): void {
    const isQueryParametersChange = currentUrl.split('?')[0] === this.currentUrl.getValue()?.split('?')[0];
    if (isQueryParametersChange) {
      this.currentUrl.next(currentUrl);
    } else {
      this.previousUrl.next(this.currentUrl.getValue());
      this.currentUrl.next(currentUrl);
    }
  }
}
